import React from "react";
import TextField from "@material-ui/core/TextField";
import store from "./../../../../store";
import { __InitDataHWRow } from "./../../../../actions/AsyncTable";
import i18n from "i18next";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Field } from "redux-form";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import Typography from '@mui/material/Typography';

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
  // __InitDataHWRow({ [input.name]: custom.default })(
  //   store.dispatch,
  //   store.getState
  // );
  delete custom.formData;
  if (JSON.parse(input.name).code === "start_number") {
    if (input.value.length > 5) input.value = input.value.substr(0, 5);
  }
  return <TextField {...input} {...custom} style={{ width: "60px" }} />;
};

const processErrors = (input, form) => {
  const array = {};
  const id = JSON.parse(input.name).id;
  for (let items of Object.keys(form)) {
    const item = JSON.parse(items);
    if (
      item.id === id &&
      (item.code === "min_time" ||
        item.code === "max_time" ||
        item.code === "start_time")
    ) {
      array[item.code] = form[items];
    }
  }
  const min = +array.min_time;
  const start = +array.start_time;
  const max = +array.max_time;
  if (start && min > start) {
    return true;
  } else if (start && max < start) {
    return false;
  }
  return false;
};

const renderSelectField = ({
  input,
  meta: { touched, error },
  i18n,
  data,
  ...custom
}) => {
  const schema = [
    0,
    7000,
    5000,
    4000,
    3500,
    3000,
    2500,
    2000,
    1800,
    1600,
    1400,
    1200,
    1000,
    900,
    800,
    700,
    600,
    500,
    400,
    300,
  ];
  const form = JSON.parse(JSON.stringify(custom.formData));
  delete custom.formData;
  return (
    <NativeSelect
      {...input}
      {...custom}
      style={{ width: "60px" }}
      error={processErrors(input, form)}
    >
      {schema.map((item, key) => {
        return (
          <option key={key} value={item}>
            {`${item / 1000} c.`}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};

const InputFromHW = ({ value, code, id, temporarily, label }) => {
  const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
  const form = store.getState().form["from-tableComponent"].values;
  const game_type =
    form[
      Object.keys(form).find(
        (el) => JSON.parse(el).code === "game_type" && JSON.parse(el).id === id
      )
    ];
  if (code === "start_time") {
    return (
      <Field
        default={value}
        name={JSON.stringify({ code, id, temporarily })}
        formData={form}
        component={renderSelectField}
        disabled={
          (code === "start_time" && game_type === "4") ||
          (code === "start_number" && game_type === "3")
        }
        type="text"
        placeholder={i18n.t(label)}
      />
    );
  }
  return location.pathname.includes("/template") && hideSubmitButton ? (
    <Typography > {value} </Typography>
  ) : (
    <Field
      default={value}
      name={JSON.stringify({ code, id, temporarily })}
      formData={form}
      component={renderTextField}
      disabled={
        (code === "start_time" && game_type === "4") ||
        (code === "start_number" && game_type === "3")
      }
      type="text"
      placeholder={i18n.t(label)}
    />
  );
};

export default withRouter(InputFromHW);
