import React from "react";

import "../../layout/Dash/Dash.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getArcards } from "../../actions/user";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { VideogameAsset } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";

const API_URL = process.env.REACT_APP_API_URL;


class ARPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ar_cards_list: null,
    };
  }
  componentDidMount() {
    this.getArCards();
  }

  async getArCards() {
    const res = await this.props.getArcards();
    let ar_cards_list = [];
    const api = API_URL.split("panel")[0];
    for (let item of res.cardResponse) {
      if (item.issued_teacher) {
        ar_cards_list.push({
          title: item.ar_code,
          url: `${api}ar-card/${item.title}/Android/icon.png`,
        });
      }
    }
    this.setState({ ar_cards_list });
  }
  render() {
    const { t } = this.props;
    return (
      <Box pt={1}>
        <Accordion
          expanded={true}
          className={
            this.state.ar_cards_list && !this.state.ar_cards_list.length
              ? "arcards no_data"
              : "ar_cards"
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="subtitle1"
              style={{ margin: "0" }}
              gutterBottom
            >
              {t("groups.ar_cards")}
            </Typography>
            <Typography>
              {this.state.ar_cards_list && this.state.ar_cards_list.length ? (
                <Badge
                  badgeContent={this.state.ar_cards_list.length}
                  style={{ marginLeft: "20px" }}
                  color="primary"
                >
                  <VideogameAsset />
                </Badge>
              ) : null}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div" className="ar_cards">
              {this.state.ar_cards_list &&
                this.state.ar_cards_list.map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="ar_card-container"
                      style={{ backgroundImage: `url(${el.url})` }}
                    >
                      <div className="ar_card-title">
                        {t(`cards.${el.title}`)}
                      </div>
                    </div>
                  );
                })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

const widthConnect = connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ getArcards }, dispatch)
);

export default withTranslation()(widthConnect(ARPage));
