import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


export default function IntegerInput(props) {
    const { id, quantity, onChange, isSelected, t, mobile } = props;
    const [localQuantity, setLocalQuantity] = useState(quantity);

    const handleDecrease = () => {
        const currentQuantity = isNaN(parseInt(localQuantity, 10)) ? 0 : parseInt(localQuantity, 10);
        const newValue = Math.max(0, currentQuantity - 1);
        setLocalQuantity(newValue);
        onChange(id, newValue);
    };

    const handleIncrease = () => {
        const currentQuantity = isNaN(parseInt(localQuantity, 10)) ? 0 : parseInt(localQuantity, 10);
        const newValue = currentQuantity + 1;
        setLocalQuantity(newValue);
        onChange(id, newValue);
    };

    const handleChange = (e) => {
        let value = e.target.value;
        const regex = /^\d*$/;
        if (!regex.test(value)) {
            value = value.slice(0, -1); 
        }
        setLocalQuantity(value);
        if (value === '' || value === '0') {
            onChange(id, 0); 
        } else {
            onChange(id, parseInt(value, 10));
        }
    };

    const isError = isSelected(id) && (localQuantity === '' || localQuantity === null);

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '130px' }}>
            {mobile && (
                <IconButton
                    onClick={handleDecrease}
                    style={{
                        width: '30%',
                        height: '100%',
                        borderRadius: '4px 0 0 4px',
                        padding: 0,
                    }}
                >
                    <RemoveIcon />
                </IconButton>
            )}

            <TextField
                id={`quantity-input-${id}`}
                value={localQuantity}
                type="number"
                color="warning"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                required
                error={isError}
                helperText={isError ? t('bankStudentsPage.fillField') : ''}
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (['-', '+', '=', '*', '/', '.', ','].includes(e.key)) {
                        e.preventDefault();
                    }
                }}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                    min: 1,
                    step: 1,
                    style: {
                        textAlign: 'center',
                        height: '30px',
                        padding: 0,
                    },
                }}
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            />

            {mobile && (
                <IconButton
                    onClick={handleIncrease}
                    style={{
                        width: '30%',
                        height: '100%',
                        borderRadius: '0 4px 4px 0',
                        padding: 0,
                    }}
                >
                    <AddIcon />
                </IconButton>
            )}
        </div>
    );
}


