import Types from '../constants/hwReducer';
import TypesDelete from './../constants/AsyncTable';
import HomeworkService from './../services/homework.service';
import HomeworkServiceV2 from './../services/homework_V2.service';
import GroupsService from '../services/groups.service';
import { closeRequestModal, openRequestModal } from './modals';
import { reloadTable } from './AsyncTable';
import { getErrorNotification } from './toast';
import i18n from 'i18next';
import { setNewHwHeight } from './user';
import store from '../store';
import { getSingleGroup } from './groups';
import { ElectricBike } from '@mui/icons-material';

function getTabeToDelete() {
    const state = store.getState();
    const { values } = state.form && state.form['from-tableComponent'];
    const e = values;
    let arrToSave = [];
    for (let i in e) {
        const obj = JSON.parse(i);
        if (obj.code !== 'do_delete') continue;
        if (obj && obj.id) {
            if (obj.code === 'do_delete' && e[i]) arrToSave.push(obj.id);
        }
    }
    return arrToSave.length ? arrToSave : false;
}

function checkArrayRange(array) {
    return array.map((item) => {
        const gameType = +item.game_type;
        const topic = +item.topic;

        switch (gameType) {
            case 3:
                if (topic > 24) item.topic = '0';
                break;
            case 4:
                if (topic < 53) item.topic = '53';
                break;
            case 5:
            case 6:
                if (topic < 12 || topic > 24) item.topic = '12';
                break;
        }

        return item;
    });
}

function sendCopyStudentHw(data) {
    return (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);
        HomeworkService.CopyStudentHw(data).then((res) => {
            if (res) {
            }
        });
    };
}

function copyHw(data) {
    return async (dispatch, getState) => {
        const res = await HomeworkService.copyHw(data);
        if (res) {
            TasksCalendar(data.group_id)(dispatch, getState);
            closeRequestModal()(dispatch, getState);
        }
        return res;
    };
}

function CopyOtherStudents(data) {
    return async (dispatch, getState) => {
        const res = await HomeworkService.CopyOtherStudents(data);
        if (res) {
            TasksCalendar(data.group_id)(dispatch, getState);
            closeRequestModal()(dispatch, getState);
        }
    };
}

function copyHwOtherGroup(data) {
    return (dispatch, getState) => {
        HomeworkService.copyHwOtherGroup(data).then((res) => {
            // TasksCalendar(data.copy_group_id)(dispatch, getState);
            closeRequestModal()(dispatch, getState);
        });
    };
}
function sendMessage(data) {
    return async (dispatch, getState) => {
        await HomeworkService.notification(data, false);
        await getSingleGroup(data.group_id)(dispatch);
        closeRequestModal()(dispatch, getState);
    };
}

function setData(args) {
    return (dispatch) => {
        dispatch({
            type: Types.CHANGE,
            homework: args,
        });
    };
}

function getAllStatData(...args) {
    return async (dispatch, getState) => {
        return await HomeworkService.callAllStat(...args);
    };
}

function createNewHw(args) {
    return async (dispatch, getState) => {
        await HomeworkService.createNewHw(args.data, args.query);
        TasksCalendar(args.data.group_id)(dispatch, getState);
        reloadTable()(dispatch, getState);
        openRequestModal({
            opened: true,
            data: {
                id: args,
                note: args.data.note,
                header: i18n.t('groups.enter_message'),
                modaltype: 'SendMessageModal',
            },
        })(dispatch, getState);
    };
} 

function createNewHw_V2(args) {
    return async (dispatch, getState) => {
        await HomeworkServiceV2.createNewHw(args.data, args.query);
        TasksCalendar(args.data.group_id)(dispatch, getState);
        reloadTable()(dispatch, getState);
        openRequestModal({
            opened: true,
            data: {
                id: args,
                note: args.data.note,
                header: i18n.t('groups.enter_message'),
                modaltype: 'SendMessageModal',
            },
        })(dispatch, getState);
    };
}

function initTemplatesName() {
    return async (dispatch, getState) => {
        const response = await HomeworkService.getTemplatesName(false);
        // .then(response => {
        const stored = getState().HW;
        let selected = stored.templatesName_select;
        // eslint-disable-next-line
        if (
            response.templatesName.length &&
            // eslint-disable-next-line
            !response.templatesName.find((el) => el.value == selected)
        ) {
            selected = null;
        }
        dispatch({
            type: Types.INIT_TEMPLATES_NAME,
            homework: {
                ...response,
                templatesName_select:
                    selected || response.templatesName[0].value,
            },
        });
    };
}

function copyTemplate(data) {
    return async (dispatch, getState) => {
        HomeworkService.copyTemplate(data).then((res) => {
            reloadTable()(dispatch, getState);

            closeRequestModal()(dispatch, getState);
        });
    };
}

function removeHWGolden(data) {
    return async (dispatch, getState) => {
        return await HomeworkService.anull(false, data);
    };
}

function removeHW(data) {
    return (dispatch, getState) => {
        HomeworkService.anull(false, data).then((response) => {
            closeRequestModal()(dispatch, getState);
            if (!data.student_id)
                TasksCalendar(data.group_id)(dispatch, getState);

            reloadTable()(dispatch, getState);
        });
    };
}

function deleteHW(data) {
    return async (dispatch, getState) => {
        const e = getState().form['from-tableComponent'].values;
        const arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            }
        }
        delete objToSave[data.id];
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i.length > 20 ? i : JSON.parse(i),
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                    id: i.length > 20 ? i : JSON.parse(i),
                });
            }
        }
        arrToSave.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );
        dispatch({
            type: TypesDelete.ADD_DATA_HW_ROW,
            payload: arrToSave,
        });
        const res = await HomeworkService.deleteHw(false, data);
        closeRequestModal()(dispatch, getState);
        return res;
    };
}

function deleteHWTeacher(data) {
    return async (dispatch, getState) => {
        const e = getState().form['from-tableComponent'].values;
        const arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            }
        }
        delete objToSave[data.id];
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            }
        }
        arrToSave.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );
        for (let i = 0; i < arrToSave.length; i++) {
            if (i && +arrToSave[i - 1].order + 1 !== +arrToSave[i].order) {
                arrToSave[i].order = +arrToSave[i - 1].order + 1;
            }
        }
        dispatch({
            type: TypesDelete.ADD_DATA_HW_ROW,
            payload: arrToSave,
        });
        const res = await HomeworkService.deleteHwTeacher(false, data);
        closeRequestModal()(dispatch, getState);
        return res;
    };
}

function deleteNotSavedRow(id) {
    return (dispatch, getState) => {
        const e = getState().form['from-tableComponent'].values;
        const arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            }
        }
        delete objToSave[id];
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            }
        }
        arrToSave.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );
        for (let i = 0; i < arrToSave.length; i++) {
            if (i && +arrToSave[i - 1].order + 1 !== +arrToSave[i].order) {
                arrToSave[i].order = +arrToSave[i - 1].order + 1;
            }
        }
        dispatch({
            type: TypesDelete.ADD_DATA_HW_ROW,
            payload: arrToSave,
        });
        closeRequestModal()(dispatch, getState);
    };
}

function processDate(item) {
    const d = new Date(item);
    const _month = String(d.getMonth() + 1).padStart(2, '0');
    const _date = String(d.getDate()).padStart(2, '0');
    return `${d.getFullYear()}-${_month}-${_date}`;
}

function getStudentsSingleHW(group_id, existingDates) {
    return async (dispatch, getState) => {
        const { list = [] } = await HomeworkService.GetStudentRelatedHW({
            group_id,
        });
        const additionalDatesList = [];

        for (const item of list) {
            if (!item.different_dates) continue;
            for (const datesItem of item.different_dates) {
                const dateObj = processStartDateObject(datesItem);
                if (existingDates.some((el) => el.start === dateObj.start))
                    continue;

                const index = additionalDatesList.findIndex(
                    (el) => el.start === dateObj.start
                );
                if (index === -1) {
                    additionalDatesList.push({
                        ...dateObj,
                        id: [item.user_id],
                        className: 'additionalDates',
                        date: processDate(dateObj.start),
                    });
                } else {
                    additionalDatesList[index].id.push(item.user_id);
                }
            }
        }

        return additionalDatesList;
    };
}

function processStartDateObject(item) {
    return {
        start: +new Date(
            +new Date(item) + +new Date(item).getTimezoneOffset() * 60 * 1000
        ),
    };
}

function processCalendarDatesArray(response) {
    let array = [];

    response.dates.sort((a, b) => +new Date(a) - +new Date(b));
    for (let item of response.dates) {
        let dateItem = processStartDateObject(item);
        array.push(dateItem);
    }
    return array;
}

function TasksCalendar(group_id) {
    return async function (dispatch, getState) {
        const response = await HomeworkService.HomeworkListTeacherCalendar(
            false,
            {
                group_id,
                size: 9999,
            }
        );
        if (!response) {
            return { redirect: '/groups' };
        }
        let array = [];
        if (response && response.dates) {
            array = processCalendarDatesArray(response);
        }
        const singleStundentDays = await dispatch(
            getStudentsSingleHW(group_id, array)
        );

        dispatch({
            type: Types.INIT_CALENDAR_TASKS,
            homework: {
                calendar_tasks: [...array, ...singleStundentDays].sort(
                    (a, b) => +new Date(a.start) - +new Date(b.start)
                ),
            },
        });
    };
}

function deleteAllHW() {
    return (dispatch, getState) => {
        let table = getState().AsyncTable.table.data;
        for (let i = 0; i < table.length; i++) {
            if (true) {
                HomeworkService.deleteHw(false, { id: table[i].id }).then(
                    (res) => {
                        if (i === table.length - 1) {
                            reloadTable()(dispatch, getState);
                            closeRequestModal()(dispatch, getState);
                        }
                    }
                );
            }
        }
    };
}

function saveHW(tasks) {
    return (dispatch, getState) => {
        setNewHwHeight()(dispatch, getState);
        dispatch({
            type: TypesDelete.ACTIVATE_FETCHING,
            payload: {
                fetching: true,
            },
        });

        const { HW } = getState();
        HomeworkService.saveModification({
            template_id: HW.templatesName_select,
            topic_number: HW.topic,
            week_day: +HW.day + 1,
            tasks,
        }).then((response) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function saveHWName(name) {
    return async function (dispatch, getState) {
        return await HomeworkService.saveModification({
            template_id: name,
            topic_number: 0,
            week_day: 1,
            tasks: [
                {
                    topic: '0',
                    digit: 1,
                    game_type: 'Ded',
                    duration: 0,
                    min_level: 1,
                    max_level: 100,
                },
            ],
        }).then((res) => {});
    };
}

function saveHWGroup(
    date,
    group_id,
    tasks,
    student_id = null,
    topic,
    templatesName,
    source
) {
    return (dispatch, getState) => {
        setNewHwHeight()(dispatch, getState);
        dispatch({
            type: Types.CHANGE,
            homework: { load: true },
        });
        const from =
            student_id && student_id !== -1 ? { student_id } : { group_id };
        tasks.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );

        for (let i = 0; i < tasks.length; i++) {
            if (i === 0 && +tasks[i].order !== 1) {
                tasks[i].order = 1;
            }
            if (i && +tasks[i - 1].order + 1 !== +tasks[i].order) {
                tasks[i].order = +tasks[i - 1].order + 1;
            }
        }
        let minMaxErrors = { text: null, index: null };
        for (let items of tasks) {
            const min = +items.min_time;
            const start = +items.start_time;
            const max = +items.max_time;
            if (items.hw_age === '-1') delete items.hw_age;

            if (start && min > start) {
                minMaxErrors.text = 'error.min_time_start_time';
                break;
            } else if (start && max !== 0 && start > max) {
                items.start_time = max;
            } else if (!start && min > max) {
                minMaxErrors.text = 'error.min_time_max_time';
                break;
            } else if (!start && max < min) {
                minMaxErrors.text = 'error.max_time_min_time';
                break;
            }
        }
        if (minMaxErrors.text) {
            getErrorNotification(i18n.t(minMaxErrors.text));
            return dispatch({
                type: Types.CHANGE,
                homework: { load: false },
            });
        }
        dispatch({
            type: TypesDelete.ADD_DATA_HW_ROW,
            payload: tasks,
        });
        HomeworkService.saveGroupHW(
            {
                date,
                ...from,
                tasks,
            },
            {
                topic,
                templatesName,
            }
        ).then((response) => {
            dispatch({
                type: Types.CHANGE,
                homework: { load: false },
            });
            reloadTable()(dispatch, getState);
        });
    };
}
function initStudentsList(group_id, date) {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.INIT_STUDENTS_LIST,
            homework: {
                students_list: [],
            },
        });
        const response = await GroupsService.simpleGetStudent(
            false,
            { size: 9999 },
            { group_id }
        );

        const stored = getState().HW;
        const { calendar_tasks } = stored;

        let list = response.list;
        const additionalIndex = calendar_tasks.findIndex(
            (el) => el.date === date
        );

        if (additionalIndex !== -1) {
            let array = [];
            for (const singleUserId of calendar_tasks[additionalIndex].id) {
                array.push(list.find((el) => el.id === singleUserId));
            }
            if (array.length) {
                list = array;
            }
        }

        dispatch({
            type: Types.INIT_STUDENTS_LIST,
            homework: {
                students_list: list,
                student: list && list.length && list[0].id,
            },
        });
    };
}

function copyLastDayHW() {
    return (dispatch, getState) => {
        const { HW } = getState();
        HomeworkService.copyLastDay({
            day: +HW.day + 1,
            template_id: HW.templatesName_select,
            topic: HW.topic,
        }).then((response) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function clearHwData() {
    return (dispatch, getState) => {
        dispatch({
            type: Types.CLEAR_HW_DATA,
        });
    };
}

// Збереження змін в моєму шаблоні
function saveHWTemplate(tasks) {
    return (dispatch, getState) => {
        setNewHwHeight()(dispatch, getState);
        dispatch({
            type: TypesDelete.ACTIVATE_FETCHING,
            payload: {
                fetching: true,
            },
        });

        const { HW } = getState();
        HomeworkServiceV2.saveModification({
            template_id: Number(HW.templatesName_select),
            topic_number: Number(HW.topic),
            week_day: +HW.day + 1,
            tasks,
        }).then((response) => {
            reloadTable()(dispatch, getState);
        });
    };
}

// Записати у стейт масив змістів ДЗ
function setTemplatesName(templatesName) {
    return {
        type: Types.SET_TEMPLATES_NAME,
        payload: templatesName,
    };
}

// Записуємо у стейт прапорець, показувати кнопку чи ні
function hideSubmitButton(isHidden) {
    return {
        type: Types.HIDE_SUBMIT_BUTTON,
        payload: isHidden,
    };
}

export {
    clearHwData,
    sendCopyStudentHw,
    createNewHw,
    setData,
    copyLastDayHW,
    initTemplatesName,
    saveHW,
    saveHWGroup,
    initStudentsList,
    saveHWName,
    TasksCalendar,
    removeHW,
    copyHwOtherGroup,
    deleteHW,
    deleteHWTeacher,
    copyTemplate,
    deleteNotSavedRow,
    deleteAllHW,
    removeHWGolden,
    getAllStatData,
    sendMessage,
    copyHw,
    CopyOtherStudents,
    getTabeToDelete,
    checkArrayRange,
    saveHWTemplate,
    setTemplatesName,
    hideSubmitButton,
    createNewHw_V2,
};
