import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BankTransactionsService from '../../services/bank-transactions.service';
import GroupsService from '../../services/groups.service';
import { setStudentsBalances } from '../../actions/bank';
import { useTranslation } from 'react-i18next';
import store from '../../store';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';
import BankTableBody from '../../components/ComponentTable/Table/BankTableBody';
import BankTableHead from '../../components/ComponentTable/Table/BankTableHead';
import BankTableToolbar from '../../components/ComponentTable/Table/BankTableToolbar';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import {
    Stack,
    Box,
    Table,
    TableContainer,
    Paper,
} from '@mui/material';
import { showCurrentStatus } from '../../actions/user';
import { filterData } from "../../actions/filterData";
import BackButton from '../../components/BalanceComponent/BackButton';
import { toast } from 'react-toastify';


const headCells = [
    {
        id: 'checkbox',
        numeric: false,
        disablePadding: false,
        label: '',
        type: 'checkbox',
    },
    {
        id: 'rowId',
        numeric: false,
        disablePadding: false,
        label: 'id',
        type: 'string',
    },
    {
        id: 'surname',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.surname',
        type: 'string',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.name',
        type: 'string',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.balance',
        type: 'string',
    },
    {
        id: 'teacher',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.teacher',
        type: 'string',
    },
    {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.group',
        type: 'group',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.status',
        type: 'status',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'bankStudentsPage.quantity',
        type: 'decimalInput',
    },
    {
        id: 'bankAccount',
        numeric: true,
        disablePadding: false,
        label: 'bankStudentsPage.bankAccount',
        type: 'link',
    },
];

function getRowData(data, groupTitles) {
    return data
        ? data.map((item) => ({
              error: false,
              rowId: item.student_id,
              name: item.student_name.split(' ').pop() || '',
              surname: item.student_name.split(' ')[0] || '',
              group: groupTitles[item.course_id] || '',
              balance: item.student_balance || '',
              studentBalances: item.students_balances || '',
              quantity: 1,
              teacher: item.teacher_name || '',
              status: item.student_status,
          }))
        : [];
};

export default function BankStudentsPage() {
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groupTitles, setGroupTitles] = useState({});
    const [errorStates, setErrorStates] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filters, setFilters] = useState({
        rowId: "",
        surname: "",
        group: "",
        teacher: "",
        status: ""
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const { emulation } = store.getState().user;
    const history = useHistory();
    const roleId = useSelector((state) => state.user.info.role_id);
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const studentsBalances = user.customer.studentsBalances;
    const mobile = usePhoneCheck();
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Отримати інформацію про группи за id
                const response = await GroupsService.GroupsList(false, {
                    role_id: +roleId,
                    page: 0,
                    size: 9999,
                    orderBy: 'lesson_day',
                    order: 'asc',
                });

                const titlesMap = response.list.reduce((map, group) => {
                    map[group.id] = group.title;
                    return map;
                }, {});
                setGroupTitles(titlesMap);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [roleId]);

    const studentsBalancesInfo = useMemo(() => {
        // if (Object.keys(groupTitles).length === 0) return [];
        return getRowData(user.customer.studentsBalances, groupTitles);
    }, [user, groupTitles]);

    useEffect(() => {
        setRows(studentsBalancesInfo);
    }, [studentsBalancesInfo]);

    const [rows, setRows] = useState(studentsBalancesInfo);

    const dispatch = useDispatch();

    const fetchStudentsBalances = async () => {
        await dispatch(setStudentsBalances());
    };

    useEffect(() => {
        fetchStudentsBalances();
    }, []);

    const handleQuantityChange = (id, value) => {
        // Знаходимо індекс рядка з вказаним id
        const rowIndex = rows.findIndex((row) => row.rowId === id);
        // Створюємо новий екземпляр масиву rows, щоб уникнути мутації стану безпосередньо
        const newRows = [...rows];
        // Оновлюємо значення 'quantity' для конкретного рядка
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            quantity: parseFloat(value) || '',
        };
        // Оновлюємо значення 'quantity' для конкретного рядка
        setRows(newRows);

        // Оновлюємо стан помилок
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: value === '',
        }));
    };

    const handleSelectAllClick = (event) => {
        if (loading) return;
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.rowId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (loading) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, id];
        } else if (selectedIndex >= 0) {
            newSelected = selected.filter((itemId) => itemId !== id);
        }

        setSelected(newSelected);
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: false, // Очищення стану помилки при кліку на чекбокс
        }));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleSuccessClick() {
        // Оберемо транзакції з 0 баланс, якщо такі є виходимо з функції
        const hasEmptyQuantity = rows
            .filter((row) => isSelected(row.rowId))
            .some(row => !row.quantity);

        if (hasEmptyQuantity) {
            toast.error(t('bankStudentsPage.hasEmptyQuantity'));
            return;
        };
          
        // Відфільтровуємо транзакції для підтвердження
        const data = rows
            .filter((row) => isSelected(row.rowId))
            .map((el) => ({
                amount: Number(el.quantity),
                customerId: Number(el.rowId),
                note: '',
            }));

        const totalAmount = data.reduce(
            (accumulator, user) => accumulator + user.amount,
            0
        );
        if (totalAmount <= balance) {
            await Promise.all(
                data.map(async (item) => {
                    try {
                        await BankTransactionsService.TransactionById(item);
                    } catch (error) {
                        console.log(error);
                    }
                })
            );
        } else {
            toast.error(t('sorokoinsForHomework.notEnoughMoney'));
        }
        setSelected([]);
    }

    const getGroupValue = (group) => {
        if (typeof group === "object") {
            return Object.values(group).find(
                (value) => value !== null && value !== undefined && value !== ""
            ) || "";
        }
        return group || "";
    };

    const handleFilterChange = (key, value) => {
        if (loading) return;
        const newFilters = { ...filters, [key]: value };     
        setFilters(newFilters);
        setRows(filterData(studentsBalancesInfo, newFilters, (status) => showCurrentStatus(status, { t, i18n })));
    };
    
    const filterConfigs = [
        { label: "ID", key: "rowId" },
        { label: t("bankStudentsPage.studentSurname"), key: "surname" },
        { label: t("bankStudentsPage.group"), key: "group", transform: (val) => getGroupValue(val) },
        { label: t("bankStudentsPage.status"), key: "status", transform: (val) => showCurrentStatus(val, { t, i18n }) }
    ];

    if ((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) {
        filterConfigs.push({ label: t("bankStudentsPage.teacher"), key: "teacher" });
    }
    
    const handleBankAccountClick = async (id) => {
        history.push(`/students/bank/${id}/students`);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <BackButton />
            <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
                <Stack direction={mobile ? "column" : "row"} justifyContent="flex-start" spacing={2}>
                    {filterConfigs.map(({ label, key, transform }) => (
                        <CustomAutocomplete
                            key={key}
                            label={label}
                            options={[...new Set(rows.map(row => (transform ? transform(row[key]) : row[key]) || ""))]}
                            inputValue={filters[key] || ""}
                            onChange={(e, newValue) => handleFilterChange(key, newValue || "")}
                            onInputChange={(e, newInputValue) => setFilters(prev => ({ ...prev, [key]: newInputValue || "" }))}
                        />
                    ))}
                </Stack>
                <BankTableToolbar
                    title={t('bankStudentsPage.selectedStudents')}
                    titleTooltipSuccess={t('bankStudentsPage.tooltipTitle')}
                    numSelected={selected.length}
                    onSuccessClick={handleSuccessClick}
                    loading={loading}
                    selectedUserIds={selected}
                    rows={rows}
                />
                {mobile ? (
                    <BankMobileBody
                        rows={rows}
                        headCells={headCells}
                        isSelected={isSelected}
                        handleSelectAllClick={handleSelectAllClick}
                        handleQuantityChange={handleQuantityChange}
                        handleClick={handleClick}
                        showCurrentStatus={showCurrentStatus}
                        handleBankAccountClick={handleBankAccountClick}
                        roleId={roleId}
                        emulation={emulation}
                        mobile={mobile}
                    />
                ) : (
                    <TableContainer style={{ maxHeight: '59vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <BankTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <BankTableBody
                                rows={rows}
                                headCells={headCells}
                                isSelected={isSelected}
                                handleClick={handleClick}
                                handleQuantityChange={handleQuantityChange}
                                handleBankAccountClick={handleBankAccountClick}
                                roleId={roleId}
                                emulation={emulation}
                                order={order}
                                orderBy={orderBy}
                            />
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
}

