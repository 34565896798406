import { io } from 'socket.io-client';
import {
    addNewMessage,
    incrementChatTotalNotViewed,
    incrementChatTotalNotViewedBySupport,
} from '../actions/supportChat';
import { getError } from '../actions/toast';
import store from './../store';
import { tokenService } from './token.service';

const API_ROOT_V2 = process.env.REACT_APP_API_V2_URL;

const SOCKET_URL = API_ROOT_V2;
const MAX_RECONNECT_ATTEMPTS = 5;

class SocketManager {
    constructor() {
        this.socket = null;
        this.currentToken = null;
        this.storeListener = null;
        this.reconnectAttempts = 0;
    }

    async handleTokenOutdated() {
        await tokenService.refreshAccessToken(() =>
            this.reconnectSocketWithNewToken()
        );
    }

    connectSocket() {
        setTimeout(() => {
            const { access_token, isRefreshTokenUpdateProcess, info } =
                store.getState().user;
            const { role_id } = info;

            if (!access_token || this.socket) {
                return;
            }

            this.socket = io(SOCKET_URL, {
                extraHeaders: {
                    auth: access_token,
                },
                reconnection: true,
                reconnectionDelay: 5000,
            });

            this.socket.on('connect', () => {
                console.log('connected');
                this.currentToken = access_token;
                // this.reconnectAttempts = 0;
            });

            this.socket.on('disconnect', () => {
                console.log('disconnect');
            });

            this.socket.on('error', (err) => {
                console.log(err);
                if (err.error === 'Token is outdated') {
                    this.handleTokenOutdated();
                }
            });

            this.socket.on('recMessage', (data) => {
                const state = store.getState();
                const activeChatId = state.suppotrChat.activeChat;
                const isChatOpen = activeChatId === data.chat_id;
                if (data) {
                    store.dispatch(
                        addNewMessage(data.chat_id, data, data.messageId)
                    );
                    if (!isChatOpen && data.counterNotViewed) {
                        if (role_id === 10) {
                            store.dispatch(
                                incrementChatTotalNotViewedBySupport(
                                    data.sender_id
                                )
                            );
                        } else {
                            store.dispatch(incrementChatTotalNotViewed());
                        }
                    }
                }
            });
        }, 500);
    }

    sendMessage(chat_id, message, fileList) {
        if (!this.socket || !this.socket.connected) {
            return;
        }

        const messageData = {
            chat_id,
            message,
            fileList,
        };

        this.socket.emit('sendMessage', messageData, (response) => {
            if (response.status !== 'ok') {
                getError({ message: response.error });
            }
        });
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
            this.socket = null;
        }
    }

    reconnectSocketWithNewToken() {
        if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
            return;
        }
        this.reconnectAttempts++;

        this.disconnectSocket();
        setTimeout(() => {
            this.connectSocket();
        }, 1000);
    }
}

const socketManager = new SocketManager();
export default socketManager;
