import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Field, change } from 'redux-form';
import NativeSelect from '@material-ui/core/NativeSelect';
import { time, count } from '../../../../constants/newEqValues';
import store from './../../../../store';
import Typography from '@mui/material/Typography';

const processErrors = (input, form) => {
    const array = {};
    const name = JSON.parse(input.name).code;
    const id = JSON.parse(input.name).id;
    for (let items of Object.keys(form)) {
        const item = JSON.parse(items);
        if (
            item.id === id &&
            (item.code === 'low_rate' ||
                item.code === 'start_rate' ||
                item.code === 'high_rate' ||
                item.code === 'low_act' ||
                item.code === 'start_act' ||
                item.code === 'high_act')
        ) {
            array[item.code] = form[items];
        }
    }
    const low = +array.low_rate;
    const middle = +array.start_rate;
    const high = +array.high_rate;

    const lowAct = +array.low_act;
    const middleAct = +array.start_act;
    const highAct = +array.high_act;

    if (name === 'low_rate') {
        return time;
    } else if (name === 'start_rate') {
        const high_split = time
            .filter((el) => (el <= low ? el : null))
            .filter(Boolean);

        const mid_output = high_split
            .filter((el) => (el >= high ? el : null))
            .filter(Boolean);
        if (!mid_output.includes(middle)) {
            input.onChange(mid_output[0]);
        }
        return mid_output;
    } else if (name === 'high_rate') {
        const high_rate = time
            .filter((el) => (el <= low ? el : null))
            .filter(Boolean);
        if (!high_rate.includes(high)) {
            input.onChange(high_rate[0]);
        }
        return high_rate;
    }

    //123123

    if (name === 'low_act') {
        return count;
    } else if (name === 'start_act') {
        const high_split = count
            .filter((el) => (el >= lowAct ? el : null))
            .filter(Boolean);
        const mid_output = high_split
            .filter((el) => (el <= highAct ? el : null))
            .filter(Boolean);
        if (!mid_output.includes(middleAct)) {
            input.onChange(mid_output[0]);
        }
        return mid_output;
    } else if (name === 'high_act') {
        const high_rate = count
            .filter((el) => (el >= lowAct ? el : null))
            .filter(Boolean);
        if (!high_rate.includes(highAct)) {
            input.onChange(high_rate[0]);
        }
        return high_rate;
    }
};

const renderSelectField = ({
    input,
    meta: { touched, error },
    i18n,
    schema,
    type,
    data,
    ...custom
}) => {
    const formData = custom.formData;
    delete custom.formData;
    const input_select = processErrors(input, formData);
    return (
        <NativeSelect
            {...input}
            {...custom}
            onChange={(e) => {
                input.onChange(e);
                if (JSON.parse(input.name).code === 'low_act') {
                    const nextStart =
                        count[
                            count.findIndex((el) => +el === +e.target.value) + 1
                        ] || count[count.length - 1];
                    const nextHigh =
                        count[
                            count.findIndex((el) => +el === +e.target.value) + 2
                        ] || count[count.length - 1];
                    store.dispatch(
                        change(
                            'from-tableComponent',
                            JSON.stringify({
                                code: 'start_act',
                                id: JSON.parse(input.name).id,
                            }),
                            nextStart
                        )
                    );
                    store.dispatch(
                        change(
                            'from-tableComponent',
                            JSON.stringify({
                                code: 'high_act',
                                id: JSON.parse(input.name).id,
                            }),
                            nextHigh
                        )
                    );
                }
            }}
            style={{ width: '100%' }}
        >
            {input_select.map((item, key) => {
                return (
                    <option key={key} value={item}>
                        {`${type === 'time' ? item / 1000 : item} ${
                            type === 'time' ? 'c' : 'д'
                        }`}
                    </option>
                );
            })}
        </NativeSelect>
    );
    // ChangeStudentGroup
};

const newEqFields =({ value, code, id, temporarily, label, data }) => {
    const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
    
    const form = store.getState().form['from-tableComponent'].values;
    const game_type =
        form[
            Object.keys(form).find(
                (el) =>
                    JSON.parse(el).code === 'game_type' &&
                    JSON.parse(el).id === id
            )
        ];
    if (game_type === '4') {
        return <></>;
    }
    return location.pathname.includes("/template") && hideSubmitButton ? (
        <div className="new_eq_block">
            <Typography > {data[code.split(',')[1]] / 1000 + 'c'} </Typography>
            <Typography > {data[code.split(',')[0]] + 'д'} </Typography>
        </div>
    ) : (
        <div className="new_eq_block">
            <Field
                default={data[code.split(',')[1]]}
                formData={form}
                schema={time}
                name={JSON.stringify({
                    code: code.split(',')[1],
                    id,
                    temporarily,
                })}
                component={renderSelectField}
                type="time"
            />
            <Field
                default={data[code.split(',')[0]]}
                formData={form}
                schema={count}
                name={JSON.stringify({
                    code: code.split(',')[0],
                    id,
                    temporarily,
                })}
                component={renderSelectField}
                type="count"
            />
        </div>
    );
};

export default withRouter(newEqFields);
