import React from 'react';
import { connect } from 'react-redux';
import '../HWSettings/hw.css';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import {
    simple,
    brother,
    friend,
    friend_brother,
    multiplication,
    AllHWTopic,
} from '../../constants/hw';
import {
    setData,
    initTemplatesName,
    saveHWTemplate,
    saveHWName,
    setTemplatesName,
    hideSubmitButton,
    getTabeToDelete,
} from '../../actions/hw';
import { setLastTopic } from '../../actions/groups';
import { openRequestModal } from '../../actions/modals';
import {
    __GetData,
    _copyDataRow,
    clearTableData,
} from '../../actions/AsyncTable';
import HomeworkV2Service from '../../services/homework_V2.service';
import ModelService_v2 from './../../services/models_V2.service';
import ComponentTable from '../../components/ComponentTable';
import TabPanel from '../../components/Tabs';
import copy from 'copy-to-clipboard';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NativeSelect from '@material-ui/core/NativeSelect';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Delete } from '@material-ui/icons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';


const templateTypes = [
    { label: 'hwTemplateSettings.schoolTemplates', value: 'schoolTemplates' },
    { label: 'hwTemplateSettings.teacherTemplates', value: 'teacherTemplates' },
    // { label: 'hwTemplateSettings.assignedTemplates', value: 'assignedTemplates' },
];

class MyTemplatesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName: null,
            showCopiedText: false, 
            link: null,
        };

        this.source = {
            getData: async (props) => {
                const { templatesName_select, templateType, topic, day } = this.props.HW;
                const query = {
                    topic: topic,
                    day: (Number(day) + 1).toString(),
                    ...props,
                };

                if (templateType === 'teacherTemplates') {
                    const res = await HomeworkV2Service.getTemplateMyById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item,
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'assignedTemplates') {
                    const res = await HomeworkV2Service.getTemplateSignById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item,
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'schoolTemplates') {
                    const res = await HomeworkV2Service.getTemplateSchoolById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item,
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                }
            },
            getModel: async () => {
                const res = await ModelService_v2.HomeworkList();
                const updatedPayload = res.payload.map((item) => {
                    if (item.type === "HW-topic" && item.code === "topic_seq_no") {
                        return {
                            ...item, 
                            code: "topic", 
                        };
                    }
                    return item;
                });
                return {
                    payload: updatedPayload,
                };
            },
        };
    }

    componentDidMount() {
        this.props.setLastTopic([{ value: 'S2' }]);  
        this.initTemplateType();
    };

    initTemplateType = async () => {
        this.props.setData({
            "templateType": "schoolTemplates",
        });
        const res = await HomeworkV2Service.getHomeworkSchema({ topic_number: this.props.HW.topic });
        this.props.setTemplatesName(res.bossHomeworks)
    }

    changeSelectTemplates = (e) => {
        this.setState({ link: null });
        this.props.setData({
            [e.target.id]: !isNaN(+e.target.value)
                ? +e.target.value
                : e.target.value,
        });

        if (e.target.value
            && this.props.HW.templateType
            && this.props.HW.templateType === 'teacherTemplates') {
            this.props.hideSubmitButton(false);    
        } else {
            this.props.hideSubmitButton(true);
        }
            
        this.props.__GetData({
            getData: async (props) => {
                const { templatesName_select, topic, day, templateType } = this.props.HW; 

                const query = {
                    topic: topic,
                    day: (Number(day) + 1).toString(), 
                    ...props, 
                };

                if (templateType === 'teacherTemplates') {
                    const res = await HomeworkV2Service.getTemplateMyById(e.target.value, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'assignedTemplates') {
                    const res = await HomeworkV2Service.getTemplateSignById(e.target.value, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'schoolTemplates') {
                    const res = await HomeworkV2Service.getTemplateSchoolById(e.target.value, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                }
            },
        });
    };

    makeToOptionFromArray = (params) =>
        params.map((element) =>
            element.map((item, key) => (
                <option key={key} value={item.value}>
                    {item.label}
                </option>
            ))
        );
     
    normalizeArrayData(arrToSave) {
        const gameType = {
            Ded: "3",
            Flash: "5",
            FlashMinus: "6",
            Mult: "4"
        };
        const  topic  = this.props.HW.topic;  

        return arrToSave.map((item) => ({
            template_id: Number(item.template_id),
            id: String(item.id),
            active: Number(item.active),
            base: Number(item.base),
            created: item.created ? new Date(item.created).toISOString() : null,
            created_at: item.created_at ? new Date(item.created_at).toISOString() : null,
            deleted_at: item.deleted_at ? new Date(item.deleted_at).toISOString() : null,
            eabacus: typeof item.eabacus === "string" ? item.eabacus === "true" ? true : false : Boolean(item.eabacus),
            game_topic: AllHWTopic.find(topic => topic.value === Number(item.topic))?.label.replace(/\s+/g, ''),
            game_topic_seq_no :Number(item.topic),
            game_type: Object.entries(gameType).find(([key, value]) => value === String(item.game_type))?.[0],
            game_type_seq_no: Number(item.game_type) || null,
            high_act: Number(item.high_act),
            high_rate: Number(item.high_rate),
            inline: typeof item.inline === "string" ? item.inline=== "true" ? true : false : Boolean(item.inline),
            low_act: Number(item.low_act),
            low_rate: Number(item.low_rate) || null,
            max_time: Number(item.max_time),
            min_time: Number(item.min_time) || null,
            option: String(item.options),
            order: Number(item.order),
            playtime: Number(item.playtime),
            rounding: Boolean(item.rounding),
            start_act: item.start_act !== null ? Number(item.start_act) : null,
            start_level: item.start_level !== null ? Number(item.start_level) : null,
            start_number: Number(item.start_number) || null,
            start_rate: Number(item.start_rate),
            start_time: Number(item.start_time),
            symmetric: Boolean(item.symmetric),
            topic: AllHWTopic.find(item => item.value === Number(topic))?.label.replace(/\s+/g, ''),
            topic_seq_no: Number(topic),
            updated_at: item.updated_at ? new Date(item.updated_at).toISOString() : null,
            week_day: Number(item.week_day),
            width: Number(item.width),
        }));
    };
    
    callBackDataHW = (e) => {        
        let table =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        let arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.temporarily) {
                if (!objToSave[obj.temporarily])
                    objToSave[obj.temporarily] = {};
                objToSave[obj.temporarily][obj.code] = e[i];
            }
        }
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                });
            }
        }
        const schemaGameValue = {
            Ded: '3',
            Flash: '5',
            FlashMinus: '6',
        };
        for (let item of arrToSave) {
            if (schemaGameValue[item.game_type])
                item.game_type = schemaGameValue[item.game_type];
            if (+item.game_type > 3 && item.base !== 0) item.base = 0;
        }
        if (table.length > arrToSave.length) {
            let numbers = table.length - arrToSave.length;
            for (let i = 0; i < numbers; i++) {
                arrToSave.push({
                    topic: '0',
                    digit: 1,
                    game_type: '3',
                    duration: 0,
                });
            }
        }
        arrToSave = this.checkArrayRange(arrToSave);
        arrToSave = this.normalizeArrayData(arrToSave);
        arrToSave.forEach((obj) => {
            if (obj.hasOwnProperty("options")) {
                delete obj.options;
            }
        });
        this.props.saveHWTemplate(arrToSave);
    };

    checkArrayRange(array) {
        for (let item of array) {
            if (+item.game_type === 3 && +item.topic > 24) item.topic = '0';
            if (+item.game_type === 4 && +item.topic < 53) item.topic = '53';
            if (
                (+item.game_type === 5 && +item.topic < 12) ||
                (+item.game_type === 5 && +item.topic > 24)
            )
                item.topic = '12';
            if (
                (+item.game_type === 6 && +item.topic < 12) ||
                (+item.game_type === 6 && +item.topic > 24)
            )
                item.topic = '12';
        }
        return array;
    }
 
    changeSelect = async (e) => {
        this.setState({ link: null });
        this.props.setData({
            "templatesName_select": null,
        });
        this.props.clearTableData(); 
        this.props.setData({
            [e.target.id]:
                (!isNaN(+e.target.value) && +e.target.value) || e.target.value,
        });
        this.props.hideSubmitButton(true);

        const selectedTopicValue = AllHWTopic.filter(
            (el) => el.value === +e.target.value
        );
        
        if (!selectedTopicValue && selectedTopicValue.length) return;
        this.props.setLastTopic([
            {
                value: selectedTopicValue[0].label,
            },
        ]);

        if (this.props.HW.templateType ) {
            const res = await HomeworkV2Service.getHomeworkSchema({ topic_number: e.target.value });
            if (this.props.HW.templateType === 'schoolTemplates') {
                this.props.setTemplatesName(res.bossHomeworks)
            } else if (this.props.HW.templateType === 'teacherTemplates') {
                this.props.setTemplatesName(res.customHomeworks)
            } else if (this.props.HW.templateType === 'assignedTemplates') {
                this.props.setTemplatesName(res.signHomeworks)
            }  
        };
    };

    handleChange = (e, newValue) => {
        this.props.setData({ day: +newValue });

        this.props.__GetData({
            getData: async (props) => {
                const { templatesName_select, topic, templateType } = this.props.HW; 

                const query = {
                    topic: topic,
                    day: (Number(newValue) + 1).toString(), 
                    ...props,
                };

                if (templateType === 'teacherTemplates') {
                    const res = await HomeworkV2Service.getTemplateMyById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'assignedTemplates') {
                    const res = await HomeworkV2Service.getTemplateSignById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                } else if (templateType === 'schoolTemplates') {
                    const res = await HomeworkV2Service.getTemplateSchoolById(templatesName_select, query);
                    const updatedList = res.map((item) => ({
                        ...item, 
                        game_type: String(item.game_type_seq_no),
                        topic: String(item.game_topic_seq_no),
                        options: item.option,
                    }));
                    return {
                        list: updatedList,
                    };
                }
            },
        });
    };

    recordTemplateNameData = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };
    createTemplateName = () => {
        this.props.saveHWName(this.state.templateName).then((res) => {
            this.props.initTemplatesName();
        });
    };

    handleOpenRequestModal = (data) => { 
        this.props.openRequestModal({
            opened: true,
            data,
        });
    };
    
    handleTemplateTypeChange = async (e) => { 
        this.setState({ link: null });
        this.props.hideSubmitButton(true); 
        this.props.clearTableData(); 
        this.props.setData({
            "templatesName_select": null,
        });
        const selectedTemplateType = e.target.value;
        this.props.setData({
            [e.target.id]: e.target.value,
        });

        if (selectedTemplateType) {
            const res = await HomeworkV2Service.getHomeworkSchema({ topic_number: this.props.HW.topic });
            if (selectedTemplateType === 'schoolTemplates') {
                this.props.setTemplatesName(res.bossHomeworks)
            } else if (selectedTemplateType === 'teacherTemplates') {
                this.props.setTemplatesName(res.customHomeworks)
            } else if (selectedTemplateType === 'assignedTemplates') {
                this.props.setTemplatesName(res.signHomeworks)
            }  
        };
    };

    handleCopy = (text) => {
        copy(text.replaceAll(' ', ''));
        this.setState({ showCopiedText: true });
        setTimeout(() => {
            this.setState({ showCopiedText: false });
        }, 3000);
    };

    getLink = () => {
        const templatesNameList = this.props.HW.templatesName;
        const templateId = this.props.HW.templatesName_select;
        const templateName = templatesNameList.find(
            template => template.id === templateId || template.template_id === templateId
        )?.name || "";

        const baseUrl = `${window.location.origin}/template`;
        const url = new URL(baseUrl);
        url.searchParams.append("id", templateId);
        url.searchParams.append("topic", this.props.HW.topic);
        url.searchParams.append("templateName", templateName);
        const link = url.toString();
        this.setState({ link: link });
    };

    render() {
        const time =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;

        let averege_time = 0;
        if (time && time.length >= 1) {
            for (let item of time) {
                if (item.playtime) averege_time += +item.playtime;
            }
        }
        averege_time = averege_time
            ? `${Math.floor(averege_time / 60) < 10 ? '0' : ''}${Math.floor(
                  averege_time / 60
              )}:${averege_time % 60 < 10 ? 0 : ''}${averege_time % 60}`
            : 0;
        const { t } = this.props;
        const days = [1, 2, 3, 4, 5, 6];
        const value = this.props.HW.day;


        const { templatesName_select, topic, templateType } = this.props.HW;
        let actionsButton = [];

        if (templateType && templatesName_select ) {
            actionsButton.push(            {
                title: t('HWSettings.copy_temp'),
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('HWSettings.copy_temp'),
                            modaltype: 'CopyTemplate',
                        },
                    }),
                Icon: ControlPointDuplicateIcon,
            })
        }

        if ((templateType === 'teacherTemplates' || templateType === 'assignedTemplates') && templatesName_select ) {
            actionsButton.push({
                title: t('hwTemplateSettings.deleteHwTemplate'),
                onClick: () => { 
                    const toDelete = getTabeToDelete();
                    this.props.openRequestModal({
                    opened: true,
                        data: {
                        id: toDelete,
                        header: t('hwTemplateSettings.deleteHwTemplate'),
                        modaltype: toDelete ? 'DeleteHwModal' : 'DeleteHwTemplateModal',
                    },
                })
            },
                Icon: Delete,
            })
        }

        const { user_id } = this.props.user.info;
        
        return (
            <>
                <h2 className="container-header"> {t('hwTemplateSettings.title')} </h2>
                <Button
                    style={{ marginBottom: "8px" }}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        this.handleOpenRequestModal({
                            id: user_id,
                            header: t('hwTemplateSettings.saveIntegratedTemplate'),
                            modaltype: 'SaveIntegratedTemplate',
                        })
                    }
                >
                    {t('hwTemplateSettings.saveIntegratedTemplate')}
                </Button>

                <div className="top_main_block_setting">
                    <div className="top_setting">
                        <p>{t('HWSettings.topic')}</p>
                        <NativeSelect
                            id="topic"
                            onChange={this.changeSelect}
                            title="select topic"
                        >
                            {this.makeToOptionFromArray([
                                simple,
                                brother,
                                friend,
                                friend_brother,
                                multiplication,
                            ])}
                        </NativeSelect>
                    </div>

                    <div className="top_setting">
                        <p>{t('hwTemplateSettings.templateType')}</p>
                        <NativeSelect
                            id="templateType"
                            onChange={this.handleTemplateTypeChange}
                        >
                            {templateTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {t(option.label)}
                                </option>
                            ))}
                        </NativeSelect>
                    </div>

                    <div className="top_setting">
                        <p>{t('HWSettings.templates_name')}</p>
                        <NativeSelect
                            value={this.props.HW.templatesName_select || ""}
                            id="templatesName_select"
                            onChange={this.changeSelectTemplates}
                            title="select topic"
                            defaultValue=""
                        >
                            <option value="" disabled >
                                {t('hwTemplateSettings.selectTemplateName')}
                            </option>
                            {this.props.HW.templatesName && this.props.HW.templatesName.map((option) => (
                                <option key={option.template_id || option.id} value={option.template_id || option.id}>
                                    {option.template_id || option.name}
                                </option>
                            ))}
                        </NativeSelect>
                    </div>
                    
                    {averege_time !== 0 ? (
                        <div className="top_setting item">
                            <p>
                                <strong>
                                    {t('HWSettings.averege_time')}:{' '}
                                    <span>
                                        {averege_time}
                                        {t('HWSettings.minutes')}
                                    </span>
                                </strong>
                            </p>
                        </div>
                    ) : null}
                </div>

                {this.props.HW.templateType
                    && this.props.HW.templateType === 'teacherTemplates'
                    && this.props.HW.templatesName_select
                    && <Stack
                        marginBottom={'16px'}
                        spacing={1}
                    >
                        {!this.state.link &&
                            (<Button
                                variant="contained"
                                color="primary"
                                onClick={this.getLink}
                            >
                                {t('hwTemplateSettings.getTemplateLink')}
                            </Button>
                            )}
                        {this.state.link && (
                            <>
                                <Typography>{t('hwTemplateSettings.templateLink')}</Typography>
                                <Button
                                    endIcon={<ContentCopyIcon />}
                                    onClick={() => this.handleCopy(this.state.link)}
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#58a315',
                                        color: '#fff',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-all',
                                        textAlign: 'left',
                                        textTransform: 'none', 
                                    }}
                                >
                                    {this.state.link}
                                </Button>
                                <Collapse in={this.state.showCopiedText}>
                           
                                    <Typography
                                        variant="caption"
                                        style={{ color: '#58a315' }}
                                    >
                                        {t('toDepositSorocoins.copied')}
                                    </Typography>
                                </Collapse>
                            </>
                        )}
                    </Stack>}

                <AppBar position="static">
                    <Tabs
                        variant="scrollable"
                        value={value}
                        onChange={this.handleChange}
                    >
                        {days.map((item, key) => (
                            <Tab
                                key={key}
                                label={`${t('groups.day')} ${item}`}
                            />
                        ))}
                    </Tabs>
                </AppBar>

                <TabPanel index="0" key="0" value="0">
                    <ComponentTable
                        source={this.source}
                        typeTable="HW"
                        blockOptions={true}
                        selectable={true}
                        callBackDataHW={this.callBackDataHW}
                        actions={
                            this.props.HW.hideSubmitButton
                                ? []
                                : [
                                    {
                                        title: t("HWSettings.copy"),
                                        onClick: (id) => this.props._copyDataRow(id),
                                        Icon: PlaylistAddIcon,
                                    },
                                    {
                                        title: t("table.delete"),
                                        onClick: (id) =>
                                            this.props.openRequestModal({
                                                opened: true,
                                                data: {
                                                    id: id,
                                                    header: t("table.delete"),
                                                    modaltype: "DeleteHwModal",
                                                },
                                            }),
                                        Icon: Delete,
                                    },
                                ]
                        }
                        actionsButton={actionsButton}
                        toolbarvisible={true}
                    />
                </TabPanel>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        HW: state.HW,
        AsyncTable: state.AsyncTable,
        form: state.form['from-tableComponent'],
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setData,
                __GetData,
                initTemplatesName,
                saveHWTemplate,
                saveHWName,
                openRequestModal,
                _copyDataRow,
                setLastTopic,
                clearTableData, 
                setTemplatesName, 
                hideSubmitButton,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(MyTemplatesPage));
