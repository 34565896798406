import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    __AddDataHWRow,
    __GetData,
    _copyDataRow,
    reloadTable,
} from '../../actions/AsyncTable';
import { openRequestModal } from '../../actions/modals';

import './main.min.css';
import './main0.min.css';
import './style.css';

import IconButton from '@material-ui/core/IconButton';
import NativeSelect from '@material-ui/core/NativeSelect';
import Tooltip from '@material-ui/core/Tooltip';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { withTranslation } from 'react-i18next';
import {
    getGroupHistoryHw,
    getSingleGroup,
    setLastTopic,
} from '../../actions/groups';
import {
    TasksCalendar,
    clearHwData,
    getTabeToDelete,
    initStudentsList,
    initTemplatesName,
    saveHWGroup,
    setData,
} from '../../actions/hw';
import ComponentTable from '../../components/ComponentTable';
import HomeworkService from './../../services/homework.service';
import ModelService from './../../services/models.service';

import FastForwardIcon from '@mui/icons-material/FastForward';
import { setExitBool } from '../../actions/groups';

class GroupSinlePageHW extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: null,
            loader: true,
            aval_dates: [],
            lastTopic: null,
        };
    }

    componentDidMount() {
        this.initFirstLoad();
    }

    componentWillUnmount() {
        this.props.clearHwData();
    }
    processAvalDates() {
        const aval_dates = this.props.HW.calendar_tasks;
        const curDate = this.processDate(+new Date());
        const arr = aval_dates.filter((el) => +new Date(curDate) <= el.start);
        this.setState({ aval_dates: arr });
    }

    dNumber(item) {
        return new Date(item).getTime();
    }

    async getData() {
        let data = await this.props.getGroupHistoryHw(false, {
            id: this.props.match.params.id,
            size: 999,
        });

        data = data.list;

        if (!data.length) {
            this.props.setLastTopic([{ value: -1 }]);
            return;
        }

        data.sort((a, b) => this.dNumber(b.date) - this.dNumber(a.date));

        this.props.setLastTopic([{ value: data[0].topic }]);
    }

    initFirstLoad = async () => {
        if (!this.props.single) {
            await this.props.getSingleGroup(this.props.match.params.id);
        }
        const calendar = await this.props.TasksCalendar(
            this.props.match.params.id
        );
        if (calendar && calendar.redirect) {
            return this.props.history.push(calendar.redirect);
        }
        this.processAvalDates();
        await this.props.initTemplatesName();
        await this.props.initStudentsList(
            this.props.match.params.id,
            this.props.match.params.date
        );
        await this.getData();
        this.setState({
            source: {
                getData: (props) =>
                    HomeworkService.HomeworkListTeacher(
                        false,
                        Object.assign(
                            {
                                topic: this.props.HW.topic,
                                templatesName:
                                    this.props.HW.templatesName_select ||
                                    this.props.HW.templatesName[0].value,
                                date: this.props.match.params.date,
                                group_id: this.props.match.params.id,
                                size: 9999,
                                student:
                                    this.props.HW.student ||
                                    this.props.HW.students_list[0].id,
                            },
                            { ...props }
                        )
                    ),
                getModel: () =>
                    ModelService.HomeworkList({
                        eq_option: this.props.single.eq_option,
                    }),
            },
            loader: false,
        });
    };

    changeSelect = (e) => {
        const { id, value } = e.target;
        const parsedValue = !isNaN(+value) ? +value : value;
        const { setData, __GetData, HW, match } = this.props;

        setData({ [id]: parsedValue });

        const updatedData = {
            topic: HW.topic,
            templatesName: HW.templatesName_select,
            date: match.params.date,
            group_id: match.params.id,
            size: 9999,
            [id === 'templatesName_select' ? 'templatesName' : id]: parsedValue,
        };

        __GetData({
            getData: (props) =>
                HomeworkService.HomeworkListTeacher(false, {
                    ...updatedData,
                    ...props,
                }),
        });
    };

    callBackDataHW = (e) => {
        let arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.temporarily) {
                if (!objToSave[obj.temporarily])
                    objToSave[obj.temporarily] = {};
                objToSave[obj.temporarily][obj.code] = e[i];
            }
        }
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                });
            }
        }
        for (let item of arrToSave) {
            item.min_time = +item.min_time;
            item.max_time = +item.max_time;
            if (item.game_type === '3') item.start_number = '0';
            if (typeof item.duration == 'string')
                item.duration = parseInt(item.duration);
            if (!item.base) item.base = '0';
            if (+item.game_type > 3 && item.base !== '0') item.base = '0';
            if (!item.order) item.order = 0;
            if (!item.start_time) item.start_time = 0;
            if (!item.start_number) item.start_number = '0';
        }

        arrToSave.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );
        arrToSave = this.checkArrayRange(arrToSave);
        this.props.saveHWGroup(
            this.props.match.params.date,
            this.props.match.params.id,
            arrToSave,
            this.props.HW.student,
            this.props.HW.topic,
            this.props.HW.templatesName_select || this.props.HW.templatesName
        );
    };

    checkArrayRange(array) {
        for (let item of array) {
            if (+item.game_type === 3 && +item.topic > 24) item.topic = '0';
            if (+item.game_type === 4 && +item.topic < 53) item.topic = '53';
            if (
                (+item.game_type === 5 && +item.topic < 12) ||
                (+item.game_type === 5 && +item.topic > 24)
            )
                item.topic = '12';
            if (
                (+item.game_type === 6 && +item.topic < 12) ||
                (+item.game_type === 6 && +item.topic > 24)
            )
                item.topic = '12';
        }
        return array;
    }

    handleBack = () => {
        this.props.history.push('/groups/' + this.props.match.params.id);
    };
    makeToOptionStudent = (params) => {
        const { t } = this.props;
        return params.map((item, key) => (
            <option key={key} value={item.id}>
                {item.first_name === 'Group'
                    ? t('groups.all')
                    : item.first_name}{' '}
                {item.last_name === 'Group'
                    ? t('groups.group_all')
                    : item.last_name}
            </option>
        ));
    };

    processDate(item) {
        const d = new Date(item);
        const _month = String(d.getMonth() + 1).padStart(2, '0');
        const _date = String(d.getDate()).padStart(2, '0');
        return `${d.getFullYear()}-${_month}-${_date}`;
    }

    async processPaginate(item) {
        const {
            t,
            HW,
            match,
            setExitBool,
            exitAccept,
            touched,
            tableCount,
            tableActual,
            openRequestModal,
            history,
            reloadTable,
        } = this.props;
        setExitBool(false);

        const currentDate = new Date(`${match.params.date}T00:00:00`);
        const targetDate = currentDate.getTime();

        const arr_date = HW.calendar_tasks.findIndex((el) => {
            const taskDate = new Date(el.start).setHours(0, 0, 0, 0);
            const targetOnlyDate = new Date(targetDate).setHours(0, 0, 0, 0);
            return taskDate === targetOnlyDate;
        });

        const array = HW.calendar_tasks;
        let next_date = '';

        if (arr_date === 0 && arr_date + item !== 1) {
            next_date = this.processDate(array[array.length - 1].start);
        } else if (arr_date + item > array.length - 1) {
            next_date = this.processDate(array[0].start);
        } else {
            next_date = this.processDate(
                array[arr_date + item]?.start || array[0].start
            );
        }

        const nextLocation = `/groups/${match.params.id}/hw/${next_date}`;

        if (!exitAccept && (touched || tableCount !== tableActual)) {
            setExitBool(true);
            openRequestModal({
                opened: true,
                data: {
                    header: t('sidebar.hw_settings'),
                    modaltype: 'PreventRouterModal',
                    onAccept: () => {
                        history.replace(nextLocation);
                        reloadTable();
                        setExitBool(false);
                    },
                },
            });
            return;
        }

        history.replace(nextLocation);
        await this.props.initStudentsList(
            this.props.match.params.id,
            next_date
        );
        reloadTable();
    }

    render() {
        const {
            t,
            HW,
            match,
            AsyncTable,
            exitAccept,
            tableActual,
            tableCount,
            touched,
        } = this.props;
        const { loader, source } = this.state;

        const actions = [
            {
                title: t('HWSettings.copy'),
                onClick: (id) => this.props._copyDataRow(id),
                Icon: PlaylistAddIcon,
            },
            {
                title: t('table.delete'),
                data: 'iDs',
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: t('table.delete'),
                            modaltype: 'DeleteHwModal',
                        },
                    }),
                Icon: DeleteIcon,
            },
        ];

        const data = match.params.date.split('-');
        const data_structured = `${data[2]}.${data[1]}.${data[0]}`;
        const timeData = AsyncTable?.table?.data || [];

        const averageTime = timeData.reduce(
            (acc, item) => acc + (item.duration ? +item.duration : 0),
            0
        );
        const averageTimeFormatted = averageTime
            ? `${String(Math.floor(averageTime / 60)).padStart(
                  2,
                  '0'
              )}:${String(averageTime % 60).padStart(2, '0')}`
            : null;

        const goldUser =
            HW.students_list?.length &&
            HW.students_list[0].first_name === 'Group' &&
            HW.students_list[0].last_name === 'Group';

        const actionsButton = [
            {
                title: t('HWSettings.copy'),
                Icon: ControlPointDuplicateIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('HWSettings.copy'),
                            modaltype: 'CopyHwModal',
                            course_id: match.params.id,
                            date: match.params.date,
                        },
                    }),
            },
            {
                title: t('HWSettings.remove_hw'),
                Icon: DeleteIcon,
                onClick: () => {
                    const toDelete = getTabeToDelete();
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: toDelete
                                ? `${t('table.delete')} ${toDelete.length}`
                                : t('HWSettings.remove_hw'),
                            id: toDelete,
                            modaltype: toDelete
                                ? 'DeleteHwModal'
                                : goldUser
                                ? 'RemoveHWModalGolden'
                                : 'removeHWModal',
                            student_id: HW.student,
                            course_id: match.params.id,
                            callBackDataHW: this.callBackDataHW,
                            date: match.params.date,
                        },
                    });
                },
            },
            {
                title: t('table.add'),
                Icon: AddIcon,
                onClick: () => this.props.__AddDataHWRow(),
                outOfMenu: true,
            },
        ];

        if (!goldUser) {
            actionsButton.unshift({
                title: t('groups.stat'),
                Icon: EqualizerIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: HW.student,
                            header: `${t('groups.stat')} | ${
                                HW.students_list.find(
                                    (el) => el.id === HW.student
                                ).first_name
                            } ${
                                HW.students_list.find(
                                    (el) => el.id === HW.student
                                ).last_name
                            }`,
                            modaltype: 'statisticsData',
                        },
                    }),
            });
        }

        if (HW.students_list?.length && !goldUser) {
            actionsButton.unshift({
                title: t('HWSettings.student_hw_copy'),
                Icon: FastForwardIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('HWSettings.student_hw_copy'),
                            modaltype: 'CopyHwStudentModal',
                            course_id: match.params.id,
                            date: match.params.date,
                            avaliableDates: this.state.aval_dates.length,
                        },
                    }),
            });
        }

        return (
            <Fragment>
                <ArrowBackIcon
                    className="ArrowBack"
                    onClick={this.handleBack}
                />
                <div className="top_main_block_setting">
                    <div className="paginate_days">
                        <Tooltip placement="top" title={t('hw.prev_day')}>
                            <IconButton
                                onClick={() => this.processPaginate(-1)}
                                disabled={
                                    !HW.calendar_tasks.length ||
                                    HW.calendar_tasks.length === 1
                                }
                            >
                                <NavigateBeforeIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <h3>{data_structured}</h3>
                        <Tooltip placement="top" title={t('hw.next_day')}>
                            <IconButton
                                onClick={() => this.processPaginate(1)}
                                disabled={
                                    !HW.calendar_tasks.length ||
                                    HW.calendar_tasks.length === 1
                                }
                            >
                                <NavigateNextIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <h3>{t('groups.filter')}</h3>
                    <div className="top_setting">
                        <p>{t('groups.students')}</p>
                        {loader ? (
                            <Skeleton />
                        ) : (
                            <NativeSelect
                                id="student"
                                onChange={this.changeSelect}
                                title={t('sales.choose_student')}
                            >
                                {this.makeToOptionStudent(
                                    HW.students_list || []
                                )}
                            </NativeSelect>
                        )}
                    </div>
                    <div className="loader"></div>
                    <div className="top_setting item">
                        <strong>
                            {`${t('HWSettings.averege_time')}:`}
                            {averageTimeFormatted ? (
                                ` ${averageTimeFormatted} ${t(
                                    'HWSettings.minutes'
                                )}`
                            ) : (
                                <Skeleton
                                    width="40px"
                                    style={{ display: 'inline-block' }}
                                />
                            )}
                        </strong>
                    </div>
                </div>
                {source && (
                    <div className="animated fadeIn faster">
                        <ComponentTable
                            source={source}
                            actions={actions}
                            actionsButton={actionsButton}
                            callBackDataHW={this.callBackDataHW}
                            blockOptions={true}
                            typeTable="HW"
                            selectable={true}
                            toolbarvisible={true}
                            exitAccept={exitAccept}
                            tableActual={tableActual}
                            tableCount={tableCount}
                            touched={touched}
                            path={match.path}
                            studentSelected={
                                !goldUser &&
                                HW.student &&
                                HW.students_list.length &&
                                HW.students_list.find(
                                    (el) => +el.id === +HW.student
                                )
                            }
                        />
                    </div>
                )}
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        HW: state.HW,
        form: state.form['from-tableComponent'],
        defaultTemplate: state.AsyncTable.table.defaultTemplate,
        AsyncTable: state.AsyncTable,
        touched:
            state.form &&
            state.form['from-tableComponent'] &&
            state.form['from-tableComponent'].anyTouched,
        tableCount:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.count,
        tableActual:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.data &&
            state.AsyncTable.table.data.length,
        exitAccept: state.groups && state.groups.acceptExitHwChange,
        single: state.groups && state.groups.single,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setData,
                openRequestModal,
                initTemplatesName,
                __GetData,
                saveHWGroup,
                initStudentsList,
                __AddDataHWRow,
                reloadTable,
                TasksCalendar,
                getGroupHistoryHw,
                setLastTopic,
                _copyDataRow,
                getSingleGroup,
                clearHwData,
                setExitBool,
            },
            dispatch
        )
);
export default withTranslation()(widthConnect(GroupSinlePageHW));
