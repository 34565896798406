import React from "react";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useTranslation } from "react-i18next";
import store from "./../../../../store";

import { change, Field } from "redux-form";
import { getDataFromTable } from "../../../../actions/AsyncTable";
import Typography from '@mui/material/Typography';

const USER_STATUSES = [
  {
    id: "not options",
    title: "no_options",
  },
  {
    id: "symmetric",
    title: "symmetric",
  },
  {
    id: "rounding",
    title: "rounding",
  },
];

const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  if (custom.disabled) {
    let name = JSON.parse(input.name);
    name.code = "options";
    store.dispatch(
      change("from-tableComponent", JSON.stringify(name), "not options")
    );
    input.value = "not options";
  }

  return (
    <NativeSelect {...input} {...custom} style={{ width: "75px" }}>
      {USER_STATUSES.map((item, key) => {
        return (
          <option key={key} value={item.id}>
            {i18n.t("table." + item.title)}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};
const options = ({ value, code, id, all }) => {
  const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
  const statusTitle = USER_STATUSES.find((item) => item.id === value)?.title || "Unknown";

  const res = getDataFromTable(id, ["game_type", "base"]);
  const { game_type, base } = res;
  const { i18n } = useTranslation();
  return location.pathname.includes("/template") && hideSubmitButton ? (
    <Typography>{i18n.t("table." + statusTitle)}</Typography>
  ) : (
    <Field
      name={JSON.stringify({ code, id })}
      disabled={game_type === "4" || Number(base) > 0}
      i18n={i18n}
      component={renderTextField}
      type="text"
      all={all}
    />
  );
};

export default withRouter(options);
