import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDevice } from '../actions/user';

const MOBILE_WIDTH = 900;

const usePhoneCheck = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    const { device } = useSelector((state) => state.user);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
            const isDeviceMobile = newWidth <= MOBILE_WIDTH;
            if (device !== isDeviceMobile) {
                dispatch(setUserDevice(isDeviceMobile));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [device, dispatch]);

    return device;
};

export default usePhoneCheck;
