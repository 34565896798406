import { ApiConnector, GET, POST, PUT, DELETE } from './requestV2.service';


export default (() => {
  const API = ApiConnector("/homeworks", true);
  return {
    getHomeworkSchema: async (query) =>
      await API(GET, `/schema/templates`, false, query).call(false, true),
    copyBossTemplate: async (data) =>
      await API(POST, `/custom/copy/boss`).call(data),
    copyTeacherTemplate: async (data) =>
      await API(POST, `/custom/copy/teacher`).call(data),
    аssignedTemplate: async (data) =>
      await API(POST, `/custom/asign`).call(data),
    getTemplatesMy: async () =>
      await API(GET, `/custom/my`).call(),
    getTemplatesSign: async () =>
      await API(GET, `/custom/sign`).call(),
    getTemplateMyById: async (id, query) => await API(GET, `/custom/my/${id}`, false, query).call(false, true),
    getTemplateSignById: async (id, query) => await API(GET, `/custom/sign/${id}`, false, query).call(false, true),
    getTemplateSchoolById: async (id, query) => await API(GET, `/custom/boss/${id}`, false, query).call(false, true),
    deleteMyTemplate: async (id) => await API(DELETE, `/custom/my/${id}`).call(),
    deleteSignTemplate: async (id) => await API(DELETE, `/custom/sign/${id}`).call(),
    saveModification: async (data) => await API(POST, `/custom/templates`).call(data),
    createNewHw: async (data, query) =>
      await API(POST, `/week`, false, query).call(data),
  };
})();

