import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import {
    multiplication,
    simple,
    brother,
    friend,
    friend_brother,
    AllHWTopic,
} from '../../../../constants/hw';
import NativeSelect from '@material-ui/core/NativeSelect';
import store from './../../../../store';
import { __InitDataHWRow } from '../../../../actions/AsyncTable';
import Typography from '@mui/material/Typography';

import { Field } from 'redux-form';

const processArray = (item) => {
    let array = [simple, brother, friend, friend_brother, multiplication];
    let output = [];
    for (let _item of array) {
        for (let body of _item) {
            output.push(body);
        }
    }
    return output;
};

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
    __InitDataHWRow({ [input.name]: custom?.data?.value })(
        store.dispatch,
        store.getState
    );
    const e = store.getState().form['from-tableComponent'].values;
    const arrToSave = [];
    const objToSave = {};
    for (let i in e) {
        const obj = JSON.parse(i);
        if (obj && obj.id) {
            if (!objToSave[obj.id]) objToSave[obj.id] = {};
            objToSave[obj.id][obj.code] = e[i];
        } else if (obj && obj.id) {
            if (!objToSave[obj.id]) objToSave[obj.id] = {};
            objToSave[obj.id][obj.code] = e[i];
        }
    }
    for (let i in objToSave) {
        arrToSave.push({
            ...objToSave[i],
            id: i.length > 20 ? i : JSON.parse(i),
        });
    }
    custom.all = arrToSave;

    let value = custom.data;
    if (!custom.gameType && +value === 0) custom.gameType = '3';
    let array = processArray(custom.gameType);
    if (
        // eslint-disable-next-line
        +custom.all.find((el) => el.id == custom.items.id).game_type !==
        +custom.gameType
    ) {
        custom.all.find((el) => el.id == custom.items.id).game_type =
            custom.gameType;
        if (!array.find((el) => el.value === value)) value = array[0].value;
        custom.all.find(
            (el) => el.id == custom.items.id
        ).topic = `${array[0].value}`;
    }
    const outputArray = checkTopicData(
        JSON.parse(JSON.stringify(array)),
        custom.gameType
    );
    return (
        <NativeSelect
            defaultValue={value?.value}
            {...input}
            style={{ minWidth: '80px' }}
        >
            {outputArray.map((item, key) => {
                return (
                    <option key={key} title={item.label} value={item.value}>
                        {item.label}
                    </option>
                );
            })}
        </NativeSelect>
    );
};

const checkTopicData = (item, gameType) => {
    const { user } = store.getState();
    const role_id =
        user && user.emulation && user.emulation.length
            ? +user.emulation[user.emulation.length - 1].info.role_id + 1
            : +user.info.role_id + 1;

    let array = [];
    let output = [];
    if (role_id === 3) {
        array = [simple, brother, friend, friend_brother, multiplication];
    } else {
        const lastTopic =
            store.getState().groups.lastTopic &&
            store.getState().groups.lastTopic[0].value;
        const topic = item.find(
            (el) =>
                el.label.replace(/\s+/g, '') === lastTopic.replace(/\s+/g, '')
        ).value;
        // const currentTopicValue =
        if (topic >= 0 && topic <= 7) {
            array = [simple];
        } else if (topic >= 8 && topic <= 11) {
            array = [simple, brother];
        } else if (topic >= 12 && topic <= 20) {
            if (gameType === '5' || gameType === '6') {
                array = [friend];
            } else if (gameType === '3') {
                array = [simple, brother, friend];
            }
        } else if (topic >= 21 && topic <= 24) {
            if (gameType === '5' || gameType === '6') {
                array = [friend, friend_brother];
            } else if (gameType === '3') {
                array = [simple, brother, friend, friend_brother];
            }
        } else if (topic >= 53 && topic <= 62) {
            if (gameType === '4') {
                array = [multiplication];
            } else if (gameType === '5' || gameType === '6') {
                array = [friend, friend_brother];
            } else if (gameType === '3') {
                array = [simple, brother, friend, friend_brother];
            }
        }
    }

    for (let _item of array) {
        for (let body of _item) {
            output.push(body);
        }
    }
    return output;
    // return item.splice(0, topic !== -1 ? topic + 1 : 999);
};

const Topic =({ all, value, code, id, temporarily }) => {

    const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
    const topicLabel = AllHWTopic.find((item) => item.value === Number(value))?.label || "Unknown";

    const form = store.getState().form['from-tableComponent'].values;
    const game_type =
        form[
        Object.keys(form).find(
            (el) =>
                JSON.parse(el).code === 'game_type' &&
                JSON.parse(el).id === id
        )
        ];
    return location.pathname.includes("/template") && hideSubmitButton ? (
        <Typography > {topicLabel} </Typography>
    ) : (
        <Field
            data={value}
            all={all}
            name={JSON.stringify({ code, id, temporarily })}
            gameType={game_type}
            items={{ value, code, id, temporarily }}
            component={renderTextField}
        />
    );
};

export default withRouter(Topic);
