import { ApiConnector, GET, POST } from './requestV2.service';

export default (() => {
    const API = ApiConnector('/book');
    return {
        createCodes: async (data) => await API(POST, 's').call(data),
        assignBook: async (data) =>
            await API(POST, 's/assign', false, false).call(data, true),
        getBooksService: async (data) => await API(GET, 's/city').call(),
        getStudentBooks: async (query) =>
            await API(GET, 's/student', false, query).call(false),
        getSingleStudentBooks: async () => await API(GET, 's/my', false).call(),
        getBooksAnalytics: async (data, query) =>
            await API(GET, 's/analitics', false, query).call(data),
        getFranchBooks: async (data, query) =>
            await API(GET, 's/city/students', false, query).call(data),
        getWarningColors: async (data, query) =>
            await API(GET, 's/color/count', false, query).call(data),
        getColorsDetails: async (data, query) =>
            await API(GET, 's/color/details', false, query).call(data),
        searchBookSupport: async (query) =>
            await API(GET, '/uuid-single', false, query).call(false, true),
        searchBookSupportHistory: async (query) =>
            await API(GET, 's/history', false, query).call(false, true),
    };
})();
