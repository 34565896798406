// eslint-disable-next-line
import { ApiConnector, GET, POST, DELETE } from "./requestV2.service";

export default (() => {
  const API = ApiConnector("/discounts");
  return {
    Single: async (data, query) =>
      await API(GET, "/single", data, query).call(data),
    Create: async (data) => await API(POST, "").call(data),
    DiscountsList: async (data, query) =>
      await API(GET, "", false, query).call(data),
    discountStatus: async (data) => await API(POST, "/approved").call(data),
    DeleteDiscount: async (data, query) =>
      await API(DELETE, "", data, query).call(data),
    RecountDiscount: async (data) => await API(POST, "/re").call(data),
  };
})();
