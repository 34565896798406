import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import store from './../../store';
import StorefrontComponent from '../../components/StorefrontComponent';
import StudentStorefrontComponent from '../../components/StudentStorefrontComponent';
import BankCustomersService from '../../services/bank-customers.service';
import { openRequestModal } from '../../actions/modals';
import {setToken, setProductsAllFranch} from '../../actions/bank';
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Paper,
    Typography,
    Button,
    Tooltip,
    IconButton,
    Box,
    Badge,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled } from '@mui/material/styles';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        background: '#00AB55',
    },
}));

export default function ShopPage(props) {
    const [vipDiscount, setVipDiscount] = useState(''); 
    const { t } = useTranslation();
    const { emulation } = store.getState().user;
    const roleId = useSelector((state) => state.user.info.role_id);
    const cartItemCount = useSelector((state) => state.bank.cart.length) || '0';
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const hasEmulation =
        emulation.length && emulation[emulation.length - 1].info;
    const dispatch = useDispatch();
    

    const getCheckExist = async () => {
        const res = await BankCustomersService.GetCheckExist();
        if (res && res.jwt) {
            dispatch(setToken(res));
            if ((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) {
                await dispatch(setProductsAllFranch());
                getVipDiscount();
            }
        } else {
            return;
        }
    };

    // Отримати віп знижку.
    async function  getVipDiscount() {
        if ((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) {
            const res = await BankCustomersService.GetVipDiscount();
            if (res && res.amount) {
                setVipDiscount(res.amount);
            } else {
                setVipDiscount('');
            }    
        }
        return;
    };

    useEffect(() => {
        getCheckExist();
    }, []);

    const handleOpenAddProductModal = () => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: '',
                    header: t('shopPage.titleModalAddProduct'),
                    modaltype: 'AddProduct',
                },
            })
        );
    };

    const handleOpenCartModal = () => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: '',
                    header: t('shopPage.titleModalCart'),
                    modaltype: 'Cart',
                },
            })
        );
    };

    const handleOpenAddDiscountForVipModal = () => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: '',
                    header: t('shopPage.titleModalAddVipDiscount'),
                    modaltype: 'AddDiscountForVip',
                    updateVipDiscount: getVipDiscount,
                },
            })
        );
    };

    return (
        <>
            <Stack spacing={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography style={{ width: '100px' }}>
                            {t('shopPage.title')}
                        </Typography>
                        {/* {roleId === 5 && ( */}
                        {(roleId === 5 || hasEmulation.role_id === 5) && user.customer.role_id === 5  && (
                            <Tooltip title={t('shopPage.tooltip')}>
                                <IconButton
                                    onClick={() => handleOpenCartModal()}
                                >
                                    <StyledBadge
                                        badgeContent={cartItemCount}
                                        color="secondary"
                                    >
                                        <ShoppingCartIcon fontSize="large" />
                                    </StyledBadge>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                    {/* {roleId === 5 && ( */}
                    {(roleId === 5 || hasEmulation.role_id === 5) && user.customer.role_id === 5 && (
                        <Box>
                            {t('shopPage.balance')}{' '}
                            <span
                                style={{
                                    // width: '200px',
                                    display: 'inline',
                                    marginLeft: '5px',
                                    color: '#44A340',
                                }}
                            >
                                {balance} SoroCoins
                            </span>
                        </Box>
                    )}
                </Stack>
                {((roleId === 2 && hasEmulation.role_id === 3) || (roleId === 3 && !emulation.length)) && (
                    <Stack direction="row">
                        <Button
                            variant="contained"
                            onClick={handleOpenAddProductModal}
                            style={{
                                width: '150px',
                                padding: '5px',
                                borderRadius: '4px',
                                marginBottom: '15px',
                            }}
                        >
                            {t('shopPage.buttonAddProduct')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleOpenAddDiscountForVipModal}
                            style={{
                                width: '150px',
                                padding: '5px',
                                borderRadius: '4px',
                                marginBottom: '15px',
                            }}
                        >
                            Знижка VIP {vipDiscount ? `${vipDiscount}%` : ''}
                        </Button>
                    </Stack>
                )}
            </Stack>
            {/* {(roleId === 3 || roleId === 4) &&
                (!hasEmulation ||
                    (hasEmulation && hasEmulation.role_id !== 5)) && ( */}
                        {(roleId === 2 || roleId === 3 || roleId === 4) &&
                (!hasEmulation ||
                    (hasEmulation && hasEmulation.role_id !== 5)) && (
                    <Paper elevation={0}>
                        <StorefrontComponent />
                    </Paper>
                )}
            {(roleId === 5 || hasEmulation.role_id === 5) && (
                <>
                    <StudentStorefrontComponent />
                </>
            )}
        </>
    );
}
