import {
    Accessibility,
    AccessibilityOutlined,
    Archive,
    ArchiveOutlined,
    Business,
    BusinessOutlined,
    DeviceHub,
    DeviceHubOutlined,
    Home,
    HomeOutlined,
    InsertChart,
    InsertChartOutlined,
    InsertDriveFile,
    InsertDriveFileOutlined,
    LocationCity,
    LocationCityOutlined,
    NearMe,
    NearMeOutlined,
    Payment,
    PaymentOutlined,
    People,
    PeopleOutlined,
    Person,
    PersonOutlined,
    PieChart,
    PieChartOutlined,
    Public,
    PublicOutlined,
    RateReview,
    RateReviewOutlined,
    School,
    SchoolOutlined,
    SettingsInputComponent,
    SettingsInputComponentOutlined,
    Today,
    TodayOutlined,
    VideogameAsset,
    VideogameAssetOutlined,
    Web,
    WebOutlined,
    Settings,
    SettingsOutlined,
} from '@mui/icons-material';
import EsorobanIcon from '../resources/esoroban';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PublicIcon from '@mui/icons-material/Public';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';

const domainLast =
    document.location.hostname.split('.')[
        document.location.hostname.split('.').length - 1
    ];

const homeworkModal = (title, item) => {
    const domainLast =
        document.location.hostname.split('.')[
            document.location.hostname.split('.').length - 1
        ];
    const locate =
        document.location.hostname.includes('sap-test') ||
        document.location.hostname.includes('local');
    const schema = {
        prod: {
            hw: `https://games.${
                domainLast === 'com' || domainLast === 'ee'
                    ? 'soroboom'
                    : 'soroban'
            }.${domainLast}`,
            srl: 'http://srl.e-soroban.com',
        },
        test: {
            hw: `https://newalg.soroban.ua`,
            srl: 'http://test-srl.e-soroban.com',
        },
    };
    openRequestModal({
        opened: true,
        data: {
            header: i18n.t(title),
            link: locate ? schema.test[item] : schema.prod[item],
            modaltype: 'HomeworkAcceptModal',
        },
    })(store.dispatch, store.getState);
};
const isDev =
    document.location.hostname.includes('sap-test') ||
    document.location.hostname.includes('local');
const isStage = document.location.hostname.includes('staging');
const envSchemaKey = isDev ? 'test' : isStage ? 'stage' : 'prod';
const schema = {
    prod: {
        ua: 'https://soroban.ua/',
        by: 'https://soroban.by/',
        md: 'https://soroban.md/',
        com: 'https://soroboom.com/',
        cz: 'https://soroboom.cz',
    },
    test: {
        ua: 'https://test-new.soroban.ua/',
        by: 'https://test.soroban.by/',
        md: 'https://test.soroban.md/',
        com: 'https://test.soroboom.com/',
        cz: 'https://test.soroboom.cz',
    },
    stage: {
        ua: 'https://staging-new.soroban.ua/',
        by: 'https://staging-new.soroban.ua/',
        md: 'https://staging-new.soroban.ua/',
        com: 'https://staging-new.soroban.ua/',
        cz: 'https://staging-new.soroban.ua/',
    },
};



const appLinks = {
    sitelink: {
        parent: {
            className: 'navigateItem',
            target: '_blank',
            href: schema[envSchemaKey][domainLast],
        },
        title: 'sidebar.to_page',
        icon: <Home className="menu-icon" />,
        iconActive: <HomeOutlined className="menu-icon" />,
    },
    help: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/help',
        },
        title: 'Help',
        icon: <HelpOutlineIcon className="menu-icon" />,
        iconActive: <HelpOutlineOutlinedIcon className="menu-icon" />,
    },
    randomkey: {
        parent: {
            className: 'navigateItem',
            onClick: () => homeworkModal('sidebar.randomkey', 'hw'),
        },
        title: 'sidebar.randomkey',
        icon: <AssignmentTurnedInIcon className="menu-icon" />,
        iconActive: <AssignmentTurnedInOutlinedIcon className="menu-icon" />,
    },
    olympiad: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/olympiad',
        },
        title: 'sidebar.olympiad',
        icon: <PublicIcon className="menu-icon" />,
        iconActive: <PublicOutlinedIcon className="menu-icon" />,
    },
    templates: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/templates',
        },
        title: 'templates.title',
        icon: <SaveIcon className="menu-icon" />,
        iconActive: <SaveOutlinedIcon className="menu-icon" />,
    },
    dashboard: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/dashboard',
        },
        title: 'sidebar.dashboard',
        icon: <Web className="menu-icon" />,
        iconActive: <WebOutlined className="menu-icon" />,
    },
    users: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/users',
        },
        title: 'sidebar.users',
        icon: <Person className="menu-icon" />,
        iconActive: <PersonOutlined className="menu-icon" />,
    },
    groups: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/groups',
        },
        title: 'sidebar.groups',
        icon: <People className="menu-icon" />,
        iconActive: <PeopleOutlined className="menu-icon" />,
    },
    finances_analitics: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/finances_analitics',
        },
        title: 'sidebar.finances_analitics',
        icon: <People className="menu-icon" />,
        iconActive: <PeopleOutlined className="menu-icon" />,
    },
    countries: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/countries',
        },
        title: 'sidebar.countries',
        icon: <Public className="menu-icon" />,
        iconActive: <PublicOutlined className="menu-icon" />,
    },
    cities: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/cities',
        },
        title: 'sidebar.cities',
        icon: <LocationCity className="menu-icon" />,
        iconActive: <LocationCityOutlined className="menu-icon" />,
    },
    offices: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/offices',
        },
        title: 'sidebar.offices',
        icon: <Business className="menu-icon" />,
        iconActive: <BusinessOutlined className="menu-icon" />,
    },
    roles: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/roles',
        },
        title: 'sidebar.roles',
        icon: <DeviceHub className="menu-icon" />,
        iconActive: <DeviceHubOutlined className="menu-icon" />,
    },
    book_generate_codes: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/book_generate_codes',
        },
        title: 'sidebar.book',
        icon: <ImportContactsIcon className="menu-icon" />,
        iconActive: <ImportContactsOutlinedIcon className="menu-icon" />,
    },
    statistics: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/statistics',
        },
        title: 'sidebar.statistics',
        icon: <PieChart className="menu-icon" />,
        iconActive: <PieChartOutlined className="menu-icon" />,
    },
    sync: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/sync',
        },
        title: 'Sync',
        icon: <NearMe className="menu-icon" />,
        iconActive: <NearMeOutlined className="menu-icon" />,
    },
    servertest: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/servertest',
        },
        title: 'Server Test',
        icon: <SettingsInputComponent className="menu-icon" />,
        iconActive: <SettingsInputComponentOutlined className="menu-icon" />,
    },
    serteficates: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/serteficates',
        },
        title: 'Сертифiкати',
        icon: <InsertDriveFile className="menu-icon" />,
        iconActive: <InsertDriveFileOutlined className="menu-icon" />,
    },
    lastchanges: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/lastchanges',
        },
        title: 'Останнi змiни',
        icon: <InsertChart className="menu-icon" />,
        iconActive: <InsertChartOutlined className="menu-icon" />,
    },
    planner: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/planner',
        },
        title: 'Планувальник завдань',
        icon: <Today className="menu-icon" />,
        iconActive: <TodayOutlined className="menu-icon" />,
    },
    clothesGrand: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/clothesgrand',
        },
        title: 'Одяг дiда',
        icon: <Accessibility className="menu-icon" />,
        iconActive: <AccessibilityOutlined className="menu-icon" />,
    },
    requests: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/requests',
        },
        title: 'sidebar.applications',
        icon: <RateReview className="menu-icon" />,
        iconActive: <RateReviewOutlined className="menu-icon" />,
    },
    students: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/students',
        },
        title: 'sidebar.students',
        icon: <School className="menu-icon" />,
        iconActive: <SchoolOutlined className="menu-icon" />,
    },
    archive: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/archive',
        },
        title: 'Архiв учнiв',
        icon: <Archive className="menu-icon" />,
        iconActive: <ArchiveOutlined className="menu-icon" />,
    },
    payments: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/payments',
        },
        title: 'sidebar.payments',
        icon: <Payment className="menu-icon" />,
        iconActive: <PaymentOutlined className="menu-icon" />,
    },
    services: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/services',
        },
        title: 'Сервiси',
        icon: <NearMe className="menu-icon" />,
        iconActive: <NearMeOutlined className="menu-icon" />,
    },
    esoroban: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/esoroban',
        },
        title: 'eSoroban',
        icon: <EsorobanIcon className="menu-icon" />,
    },
    HWSettings: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/HWSettings',
        },
        title: 'sidebar.hw_settings',
        icon: <Settings className="menu-icon" />,
        iconActive: <SettingsOutlined className="menu-icon" />,
    },
    ar: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/ar',
        },
        title: 'sidebar.AR',
        icon: <VideogameAsset className="menu-icon" />,
        iconActive: <VideogameAssetOutlined className="menu-icon" />,
    },
    relocate: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/relocate',
        },
        title: 'sidebar.relocate',
        icon: <TransferWithinAStationIcon className="menu-icon" />,
        iconActive: (
            <TransferWithinAStationOutlinedIcon className="menu-icon" />
        ),
    },
    homework: {
        parent: {
            className: 'navigateItem',
            onClick: () => homeworkModal('hw.title', 'hw'),
        },
        title: 'Homework',
        icon: <MenuBookIcon className="menu-icon" />,
        iconActive: <MenuBookOutlinedIcon className="menu-icon" />,
    },
    analytics: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/analytics',
        },
        title: 'sidebar.analytics',
        icon: <AssessmentIcon className="menu-icon" />,
        iconActive: <AssessmentOutlinedIcon className="menu-icon" />,
    },
    online_lesson: {
        parent: {
            className: 'navigateItem',
            onClick: () => homeworkModal('sidebar.online_lesson', 'srl'),
        },
        title: 'sidebar.online_lesson',
        icon: <LibraryBooksIcon className="menu-icon" />,
        iconActive: <LibraryBooksOutlinedIcon className="menu-icon" />,
    },
    shop: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/shop',
        },
        title: 'sidebar.shop',
        icon: <StoreIcon className="menu-icon" />,
        iconActive: <StoreOutlinedIcon className="menu-icon" />,
    },
    template: {
        parent: {
            className: 'navigateItem',
            activeClassName: 'is-active',
            to: '/template',
        },
        title: 'sidebar.myTemplates',
        icon: <FeedIcon className="menu-icon" />,
        iconActive: <FeedOutlinedIcon className="menu-icon" />,
    },
};

export { appLinks };
