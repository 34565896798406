import React, { Fragment } from 'react';
import ComponentTable from '../../components/ComponentTable';

import {
    Add,
    Delete,
    Edit,
    ExitToApp,
    MonetizationOn,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeRequestModal, openRequestModal } from '../../actions/modals';

import SCHEMAS from '../../constants/modalSchemas';
import ModelService from './../../services/models.service';
import UsersService from './../../services/users.service';

import { withTranslation } from 'react-i18next';
import { __GetModel } from '../../actions/AsyncTable';
import { deleteUser, getUsers } from '../../actions/getUsers';
import { emulation } from '../../actions/user';

import LockIcon from '@mui/icons-material/Lock';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { apiService } from '../../services/requestV2.service';

/* eslint-disable no-unused-vars */
class UsersPage extends React.Component {
    componentWillUnmount() {
        apiService.cancelAllRequests();
    }
    getEmulationRoleId() {
        return this.props.user &&
            this.props.user.emulation &&
            this.props.user.emulation.length
            ? +this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id + 1
            : +this.props.user.info.role_id === 10
            ? 2
            : +this.props.user.info.role_id + 1;
    }
    getSource() {
        return {
            getData: (page, size, sort, filter) => {
                return UsersService.UsersList(false, {
                    role_id: this.getEmulationRoleId(),
                    page: page || 0,
                    size: size || 10,
                    orderBy: (sort && sort.orderBy) || null,
                    order: (sort && sort.order) || null,
                    filter,
                });
            },
            getModel: () =>
                ModelService.UsersList(false, {
                    role_id: this.getEmulationRoleId(),
                }),
        };
    }
    render() {
        const { user } = this.props;
        // const isUserIdOne = user.info.role_id === 1;
        // const isUserIdOne = true;

        const source = this.getSource();
        const { t } = this.props;
        const replinish =
            (this.props.user &&
            this.props.user.emulation &&
            this.props.user.emulation.length
                ? +this.props.user.emulation[
                      this.props.user.emulation.length - 1
                  ].info.role_id
                : // eslint-disable-next-line
                  +this.props.user.info.role_id) === 2
                ? [
                      {
                          title: t('table.top_up_balance'),
                          onClick: (id) =>
                              this.props.openRequestModal({
                                  opened: true,
                                  data: {
                                      id: id,
                                      header: t('table.top_up'),
                                      modaltype: 'MonetizationFranch',
                                  },
                              }),
                          Icon: MonetizationOn,
                      },
                  ]
                : [];

        const blockFranch =
            this.getEmulationRoleId() === 3
                ? [
                      {
                          title: t('payments.blockFrinchise'),
                          onClick: (id, data) => {
                              this.props.openRequestModal({
                                  opened: true,
                                  data: {
                                      id: id,
                                      header: `${t(
                                          'payments.blockFrinchise'
                                      )}: ${data.first_name} ${data.last_name}`,
                                      modaltype: 'BlockFranchModal',
                                  },
                              });
                          },
                          Icon: LockIcon,
                          outOfMenu: true,
                          franchControl: true,
                      },
                  ]
                : [];

        const actions = [
            ...blockFranch,
            ...replinish,
            {
                title: t('modals_name.edit'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            schema: SCHEMAS.editUser,
                            header: t('modals_name.edit'),
                            modaltype: 'EditUserModal',
                        },
                    }),
                Icon: Edit,
            },
            {
                title: t('table.delete'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            schema: SCHEMAS.requestDelete,
                            header: t('table.delete'),
                            modaltype: 'DeleteUserModal',
                        },
                    }),
                Icon: Delete,
            },
            {
                title: t('table.emulation'),
                onClick: async (id) => {
                    await this.props.emulation({
                        id,
                        path: window.location.pathname,
                    });
                    const source = this.getSource();
                    this.props.__GetModel(source);
                },
                Icon: ExitToApp,
            },
            {
                title: 'SOROBANK',
                onClick: (id) => {
                    // this.props.history.push(`/users/bank/${id}`);
                    this.props.history.push(`/users/bank/${id}/user`);
                },
                Icon: AttachMoneyIcon,
            },
        ];

        // if (isUserIdOne) {
        //     actions.push({
        //         title: 'SOROBANK',
        //         onClick: (id) => {
        //             this.props.history.push(`/users/bank/${id}`);
        //         },
        //         Icon: AttachMoneyIcon,
        //     });
        // }

        return (
            <Fragment>
                <h2 className="container-header"> {t('users.title')} </h2>
                <ComponentTable
                    source={source}
                    actions={actions}
                    selectable={true}
                    enableFilter={true}
                    title="Список пользователей"
                    enableSettings
                    toolbarvisible={true}
                    actionsButton={[
                        {
                            title: t('table.add'),
                            Icon: Add,
                            onClick: () =>
                                this.props.openRequestModal({
                                    opened: true,
                                    data: {
                                        role_id: this.getEmulationRoleId(),
                                        schema: SCHEMAS.addUser,
                                        header: t('modals_name.add_user'),
                                        modaltype: 'AddUserModal',
                                    },
                                }),
                        },
                    ]}
                />
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                closeRequestModal,
                getUsers,
                deleteUser,
                emulation,
                __GetModel,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(UsersPage));
