import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useEffect, useRef } from 'react';

export default ({ action, input, type, meta: { touched, error }, label }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const cleanInput = (value) => value.replace(/\s+/g, ' ').trim();

    return (
        <FormControl
            error={Boolean(touched && error)}
            variant="filled"
            fullWidth
        >
            <InputLabel>{label}</InputLabel>
            <Input
                {...input}
                autoComplete="off"
                variant="filled"
                type={type}
                endAdornment={action}
                ref={inputRef}
                onChange={(e) => {
                    const cleanedValue = cleanInput(e.target.value);
                    input.onChange(cleanedValue);
                }}
                onPaste={(e) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData.getData('text');
                    const cleanedText = cleanInput(pastedText);
                    input.onChange(cleanedText);
                }}
            />
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
