import React from "react";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../store";
import { __InitDataHWRow } from "./../../../../actions/AsyncTable";
import { AllHWTopic } from "../../../../constants/hw";
import Typography from '@mui/material/Typography';

import { Field } from "redux-form";

const schemaGame = [
  {
    label: "Ded",
    value: "3",
  },
  {
    label: "Flash +",
    value: "5",
  },
  {
    label: "Flash -",
    value: "6",
  },
  {
    label: "Mult",
    value: "4",
  },
];
const schemaGameValue = {
  Ded: "3",
  "Flash +": "5",
  "Flash -": "6",
  Mult: "4",
};

const checkTopicData = () => {
  const { user } = store.getState();
  const role_id =
    user && user.emulation && user.emulation.length
      ? +user.emulation[user.emulation.length - 1].info.role_id + 1
      : +user.info.role_id + 1;
  if (role_id === 3) {
    return 999;
  } else {
    const lastTopic =
      store.getState().groups.lastTopic &&
      store.getState().groups.lastTopic[0].value;
    const topic = AllHWTopic.find(
      (el) => el.label.replace(/\s+/g, "") === lastTopic
    );
    const spliceValue = 999;
    if (topic) {
      const value = topic.value;
      if (value >= 0 && value <= 7) {
        return 1;
      } else if (value >= 8 && value <= 11) {
        return 1;
      } else if (value >= 12 && value <= 24) {
        return 3;
      } else if (value >= 53 && value <= 62) {
        return 4;
      }
    }
    return spliceValue;
  }
};

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
  __InitDataHWRow({ [input.name]: custom.data })(
    store.dispatch,
    store.getState
  );
  const arr = JSON.parse(JSON.stringify(schemaGame));
  return (
    <NativeSelect {...input} {...custom} style={{ minWidth: "100px" }}>
      {arr.splice(0, checkTopicData()).map((item, key) => (
        <option key={key} value={item.value}>
          {item.label}
        </option>
      ))}
    </NativeSelect>
  );
};
const HW_game_type =({ value, code, id, temporarily }) => {
  const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
  const gameType = Object.keys(schemaGameValue).find((key) => schemaGameValue[key] === value) || "Unknown";

  return location.pathname.includes("/template") && hideSubmitButton ? (
    <Typography > {gameType} </Typography>
  ) : (
    <Field
      data={schemaGameValue[value]}
      name={JSON.stringify({ code, id, temporarily })}
      component={renderTextField}
      type="text"
    />
  );
};

export default withRouter(HW_game_type);
