import { getError, getErrorTranslate, getSuccess } from '../actions/toast';
import { PROD_API } from '../constants/urlAPI';
import store from './../store';
import { tokenService } from './token.service';

const API_ROOT_V2 = process.env.REACT_APP_API_V2_URL;
const API_ROOT = process.env.REACT_APP_API_URL;

class ApiService {
    constructor() {
        this.storeListener = null;
        if (ApiService.instance) {
            return ApiService.instance;
        }
        this.isRefreshing = false;
        this.refreshPromise = null;
        this.activeRequests = new Map();
        ApiService.instance = this;
        return this;
    }

    _getRequestId(method, endpoint, rawData) {
        return `${method}:${endpoint}:${JSON.stringify(rawData)}:${Number(
            new Date()
        )}`;
    }

    cancelAllRequests() {
        const requests = Array.from(this.activeRequests.values());
        requests.forEach((controller) => controller.abort());
        this.activeRequests.clear();
    }

    _cancelDuplicateRequest(requestId) {
        if (this.activeRequests.has(requestId)) {
            const controller = this.activeRequests.get(requestId);
            controller.abort();
            this.activeRequests.delete(requestId);
        }
    }

    async makeRequest(props) {
        const {
            method,
            endpoint,
            rawData = false,
            multipart = false,
            sendEmulation = true,
            oldApi = false,
            noErrorMessage = false,
            responseType = 'json',
            newAPI,
        } = props;
        const requestId = this._getRequestId(method, endpoint, rawData);

        // this._cancelDuplicateRequest(requestId);

        const controller = new AbortController();
        const { signal } = controller;

        const { access_token, emulation } = store.getState().user;
        const headers = oldApi
            ? new Headers({
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Accept: 'application/json',
              })
            : new Headers({
                  // 'Content-Type': 'application/json',
                  Accept:
                      newAPI && endpoint.includes('upload')
                          ? '*/*'
                          : 'application/json',
                  ...(newAPI && endpoint.includes('upload')
                      ? {}
                      : { 'Content-Type': 'application/json' }),
              });

        const tokenType = newAPI ? 'Bearer' : 'Basic';

        if (access_token) {
            headers.append('authorization', `${tokenType} ${access_token}`);
        }
        if (sendEmulation && emulation && emulation.length) {
            headers.append(
                'emulation',
                `${tokenType} ${emulation[emulation.length - 1].token}`
            );
        }

        const options = {
            method,
            headers,
            mode: 'cors',
            cache: 'default',
            signal,
        };

        const body =
            !!rawData &&
            (multipart
                ? this._formData(rawData)
                : oldApi
                ? this._makeToQuery(rawData, true)
                : JSON.stringify(rawData));

        if (!!body) {
            options.body = body;
        }

        const { user } = store.getState();
        const id_admin = user.info && user.info.role_id;

        const mainAPIUrl = newAPI ? API_ROOT_V2 : API_ROOT;

        const APIURL = window.location.pathname.includes('/hidden')
            ? PROD_API
            : mainAPIUrl;

        const request = oldApi
            ? new Request(oldApi, options)
            : new Request(APIURL + endpoint, options);

        this.activeRequests.set(requestId, controller);

        if (
            id_admin &&
            !endpoint.includes('auth') &&
            id_admin === 10 &&
            !(newAPI && endpoint.includes('/chats')) &&
            method !== 'GET'
        ) {
            return getErrorTranslate('error.no_access_to_fetch');
        }

        try {
            const response = await fetch(request);
            let json;
            if (responseType === 'blob') {
                return await response.blob();
            } else {
                json = await response.json();
            }

            if (json && newAPI) {
                if (!json.statusCode && !json.message) return json;
                if (
                    json.statusCode === 401 &&
                    json.message === 'Unauthorized'
                ) {
                    return this._handleTokenOutdated(() =>
                        this.makeRequest(props)
                    );
                } else if (json.error === 'Emulation token is outdated') {
                    return this._handleEmulationTokenOutdated(() =>
                        this.makeRequest(props)
                    );
                }
            }

            if (json.status && !newAPI) {
                if (
                    method !== 'GET' &&
                    !endpoint.includes('auth') &&
                    !endpoint.includes('correct-schema-hw')
                ) {
                    getSuccess();
                }
                if (json.payload.successCode) {
                    getSuccess();
                }
                return json.payload;
            } else if (
                json.error === 'Token is outdated' ||
                (json.statusCode === 401 && json.message === 'Unauthorized')
            ) {
                return this._handleTokenOutdated(() => this.makeRequest(props));
            } else if (json.error === 'Emulation token is outdated') {
                return this._handleEmulationTokenOutdated(() =>
                    this.makeRequest(props)
                );
            } else {
                const error = new Error(json.error);
                error.name = 'API';
                error.number = response.status;
                error.url = response.url;
                throw error;
            }
        } catch (error) {
            this.activeRequests.delete(requestId);
            return this._handleError(error, oldApi, noErrorMessage);
        }
    }

    _formData(object) {
        const formData = new FormData();
        Object.keys(object).forEach((key) => {
            if (object[key] instanceof File) {
                formData.append(key, object[key]); // Якщо значення — це файл, додаємо його
            } else {
                formData.append(key, object[key]); // Для інших значень додаємо як звичайно
            }
        });
        return formData;
    }

    _makeToQuery(query, noGet = false) {
        if (!query) return '';
        const str = [];
        for (let p in query) {
            if (query.hasOwnProperty(p)) {
                let value = query[p];

                // Check if value is a Date object and format it
                if (value instanceof Date) {
                    value = value.toISOString(); // Format date to ISO 8601 format
                }

                str.push(
                    `${encodeURIComponent(p)}=${encodeURIComponent(value)}`
                );
            }
        }
        return noGet ? str.join('&') : `?${str.join('&')}`;
    }

    _handleError({ message, name, number, url }, oldApi, noErrorMessage) {
        if (!!oldApi) {
            return true;
        }

        if (number === 403) {
            if (message === 'Token has been changed') {
                localStorage.setItem('globalStore', JSON.stringify({}));
                document.location = `${document.location.origin}/login`;
            } else {
                if (!noErrorMessage) {
                    getError({ message, name, number, url });
                }
                return Promise.reject({ message, name, number, url });
            }
        }

        if (number === 401 && message === 'Token recovery is not subject to') {
            localStorage.setItem('globalStore', JSON.stringify({}));
            document.location.reload(true);
        }

        if (!noErrorMessage) {
            getError({ message, name, number, url });
        }

        return Promise.reject({ message, name, number, url });
    }

    async _handleTokenOutdated(retryRequest) {
        return await tokenService.refreshAccessToken(retryRequest);
    }

    async _handleEmulationTokenOutdated(retryRequest) {
        return await tokenService.refreshEmulationToken(retryRequest);
    }
}

export const apiService = new ApiService();

const GET = (props) => apiService.makeRequest({ method: 'GET', ...props });
const POST = (props) => apiService.makeRequest({ method: 'POST', ...props });
const PUT = (props) => apiService.makeRequest({ method: 'PUT', ...props });
const DELETE = (props) =>
    apiService.makeRequest({ method: 'DELETE', ...props });

export { GET, POST, PUT, DELETE };

export function ApiConnector(prefix = '', newAPI = false) {
    return (
        callback,
        endpoint,
        multipart = false,
        query = false,
        sendEmulation = true,
        oldApi = false
    ) => ({
        async call(
            body = false,
            noErrorMessage = false,
            responseType = 'json'
        ) {
            try {
                return await callback({
                    endpoint:
                        prefix + endpoint + apiService._makeToQuery(query),
                    rawData: body,
                    multipart,
                    sendEmulation,
                    oldApi,
                    noErrorMessage,
                    responseType,
                    newAPI,
                });
            } catch (message) {
                if (message && noErrorMessage) {
                    return Promise.reject(message);
                }
            }
        },
    });
}
