import React, { useCallback, useEffect } from 'react';
import { Field } from 'redux-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import store from './../../../../../store';
import InputView from './InputView';
import PhoneMaskView from './PhoneMaskView';

import normalizeRules from './../normalizeRules';

import DeleteIcon from '@material-ui/icons/Delete';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { __SetFilter } from '../../../../../actions/AsyncTable';

export default ({ label, fields, meta: { error }, mindex, ...custom }) => {
    const { t } = useTranslation();

    const handleAddButton = useCallback(
        (isInitialEmpty) => {
            let form = store.getState().form;
            const filterField = Object.keys(form).find((field) =>
                field.includes('filter')
            );

            if (isInitialEmpty && form[filterField]?.initial?.[fields.name]) {
                return;
            }

            if (
                fields.name === 'email' &&
                form[filterField]?.registeredFields?.['email[0]']
            ) {
                return;
            }
            fields.push();
        },
        [fields]
    );

    useEffect(() => {
        if (!fields.length) {
            handleAddButton(true);
        }
    }, []);
    return (
        <Card>
            <CardContent>
                <div style={{ display: 'flex', marginBottom: 16 }}>
                    <Stack spacing={3} direction={'row'} alignItems={'center'}>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            style={{ marginBottom: 'unset' }}
                        >
                            {label}
                        </Typography>
                        <IconButton
                            onClick={() =>
                                __SetFilter()(store.dispatch, store.getState)(
                                    custom.data
                                )
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                    <div style={{ flex: 1 }} />
                    <Tooltip placement="top" title={t('table.add_rule')}>
                        <IconButton onClick={handleAddButton}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <Grid container spacing={3} justifyContent="flex-end">
                    {fields.map((member, index) => (
                        <Grid item xs={12} key={index}>
                            <Field
                                name={member}
                                type="text"
                                normalize={normalizeRules[fields.name]}
                                component={
                                    custom.data.code === 'phone'
                                        ? PhoneMaskView
                                        : InputView
                                }
                                action={
                                    <InputAdornment position="end">
                                        <Tooltip
                                            placement="top"
                                            title={t('table.remove_rule')}
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                }
                                label={label}
                            />
                        </Grid>
                    ))}
                    {error && (
                        <Grid item xs={12}>
                            <Typography
                                variant="body2"
                                component="p"
                                color="error"
                            >
                                {error}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
