// eslint-disable-next-line
import { ApiConnector, GET, POST } from "./requestV2.service";

export default (() => {
  const API = ApiConnector("/auth");
  return {
    Login: async (data) => await API(POST, "/login").call(data),
    SelectProfile: async (data) =>
      await API(POST, "/profile-selection").call(data),
    Resume: async (data) => await API(POST, "/resume").call(data),
    Emulation: async (data) =>
      await API(POST, "/emulation", false, false, false).call(data),
    OldApiCheck: async (data) =>
      await API(POST, "/authorization", false, false, false, true).call(data),
  };
})();

const asd = { refresh_token: "string", status: "boolean", token: "string" };
