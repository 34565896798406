import { ApiConnector, GET, POST, PUT } from './bank-request.service';

export default (() => {
    const API = ApiConnector('/transactions');
    return {
        EditTransaction: async (data, noMessages = false) =>
            await API(PUT, '/').call(data, noMessages, true),
        AllTransactions: async (query) =>
            await API(GET, `/teacher?courseId=${query || ''}`).call(),
        AllRootTransactions: async (query) => await API(GET, '/root').call(),
        AllProducts: async (query) =>
            await API(GET, `/products?courseId=${query || ''}`).call(),
        RequestProducts: async (query) =>
            await API(GET, '/request/products?customerId=${query}').call(),
        TransferToCard: async (data) =>
            await API(POST, '/transfer-to-card').call(data, true),
        AccountDeposit: async (data, noMessage) =>
            await API(POST, '/charge').call(data, noMessage),
        WithdrawalTransaction: async (data, noMessage) =>
            await API(POST, '/cash').call(data, noMessage),
        ComfirmTransaction: async (data, noMessage = false) =>
            await API(POST, '/comfirm').call(data, false),
        DeclineTransaction: async (data) =>
            await API(POST, '/decline').call(data),
        AcceptTransactions: async (data) =>
            await API(POST, '/transfer/course/custom').call(data),
        OrderSorocoins: async (data) => await API(POST, '/order').call(data),
        TransactionById: async (data) => await API(POST, '/transfer').call(data),
        GetTransactionsHistory: async () => await API(GET, '/history/my').call(),
        GetTransactionsHistoryById: async (query) => await API(GET, `/history/${query}`).call(),
        GetOrdersHistory: async () => await API(GET, '/history/buy/my').call(),
        GetOrdersHistoryById: async (query) => await API(GET, `/history/buy/${query}`).call(),
        GetGroupTransactionsHistory: async (courseId, query) =>
            await API(GET, `/history/course/${courseId}`, false, query).call(false, true),
    };
})();
