import React from "react";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../store";
import { __InitDataHWRow } from "./../../../../actions/AsyncTable";
import Typography from '@mui/material/Typography';

import { Field, change } from "redux-form";

const digits = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
];

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
  // __InitDataHWRow({ [input.name]: custom.data })(
  //   store.dispatch,
  //   store.getState
  // );
  return (
    <NativeSelect {...input} {...custom}>
      {digits.map((item, key) => (
        <option key={key} value={item.value}>
          {item.value}
        </option>
      ))}
    </NativeSelect>
  );
};
const Digits =({ value, code, id, temporarily }) => {
  const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
  
  return location.pathname.includes("/template") && hideSubmitButton ? (
    <Typography > {value} </Typography>
  ) : (
    <Field
      data={value}
      name={JSON.stringify({ code, id, temporarily })}
      component={renderTextField}
      type="text"
    />
  );
};

export default withRouter(Digits);
