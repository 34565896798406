// eslint-disable-next-line
import { ApiConnector, DELETE, GET, POST } from './requestV2.service';

export default (() => {
    const API = ApiConnector('/students');
    const API_v2 = ApiConnector('/students', true);
    return {
        StudentsList: async (data, query) =>
            await API(GET, '', false, query).call(data),
        GetStudentsStatuses: async (data, query) =>
            await API(GET, '/count', false, query).call(data),
        GetSingleARCards: async (data, query) =>
            await API(GET, '/arcard', false, query).call(data),
        GetArCards: async (data, query) =>
            await API(GET, '/arcard/count', false, query).call(data),
        GetReferDiscounts: async () =>
            await API(GET, '/refer ', false, false).call(),
        GetFamilyDiscount: async () =>
            await API(GET, '/family', false, false).call(),
        DeleteArCard: async (query) =>
            await API(DELETE, '/arcard', false, query).call(),
        GenerateArCard: async (data) =>
            await API(POST, '/arcard/generate', false, false).call(data),
        DiscountsCount: async () =>
            await API(GET, '/discount/count', false, false).call(),
        DiscountList: async (data, query) =>
            await API(GET, '/discount/list', false, query).call(data),
        BirthdayList: async (query) =>
            await API(GET, '/birth', false, query).call(),
        CorrectSchemaHw: async (data, query = false) =>
            await API(POST, '/correct-schema-hw', false, query).call(data),
        CountCreatedStudent: async () =>
            await API_v2(GET, '/count/created').call(),
        NewStudentsCreated: async (data, query) =>
            await API(GET, '/list/created', false, query).call(data),
        SupportList: async (data, query) =>
            await API(GET, '/support', false, query).call(data),
    };
})();
