import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';

import { reduxForm, Form } from 'redux-form';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import Head from './Table/Head';
import Body from './Table/Body';
import Header from './Table/Header';
import Pagination from './Table/Pagination';
import FilterView from './Table/Filter/View';
import * as ActionCreators from './../../actions/AsyncTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import store from '../../store';
import { setNewHwHeight } from '../../actions/user';
import IconButton from '@material-ui/core/IconButton';
import { openRequestModal } from '../../actions/modals';

import i18n from 'i18next';
import { Box, useMediaQuery } from '@mui/material';
import usePhoneCheck from '../../hooks/usePhoneCheck';

const styled = withStyles(() => ({
    tableWrapper: {
        overflowX: 'auto',
        maxHeight: '63vh',
    },
}));

const onSubmit = (data, dispatch, props) => {
    const { t } = props;
    const {
        callBackDataHW,
        path,
        exitAccept,
        touched,
        tableActual,
        tableCount,
        studentSelected,
    } = props;
    if (
        !exitAccept &&
        path &&
        path.includes('/groups/:id/hw/:date') &&
        (!!touched || !(tableCount === tableActual)) &&
        callBackDataHW
    ) {
        openRequestModal({
            opened: true,
            data: {
                header: t('buttons.set'),
                modaltype: 'AcceptHwModal',
                student:
                    studentSelected &&
                    `${studentSelected.first_name} ${studentSelected.last_name}`,
                acceptHandler: callBackDataHW,
                acceptHandlerData: data,
            },
        })(store.dispatch, store.getState);
    } else {
        callBackDataHW(data);
    }
};

const AsyncTable = (props) => {
    const currentTable = useMemo(() => {
        if (!props?.default || !props?.table) {
            return {};
        }
        return {
            ...JSON.parse(JSON.stringify(props.default)),
            ...props.table,
        };
    }, [props?.default, props?.table]);

    const {
        rowsPerPageOptions,
        count,
        rowsPerPage,
        page,
        model,
        filter,
        data,
        cities,
        flagUpdate,
        subdivisions,
        counterparties,
        ordersStatusList,
        fetching,
        isEmpty,
        expanded,
        sort,
    } = currentTable;

    const {
        __RowsPerPage,
        __ChangePage,
        __ChangeSort,
        __SetFilterValues,
        __GetData,
        __GetModel,
        __SetFilter,
        __ExpandFilterView,
        __RollUpFilterView,
        __typePayments,
        __sumPayments,
        t,
    } = props;

    const {
        actionsButton,
        blockOptions,
        classes,
        headerTable,
        enableFilter,
        actions = [],
        source,
        userData = false,
        typeTable,
        handleSubmit,
        callBackDataHW = null,
        fetchOnFilter,
        bankStudents,
        bankStudentsVip,
    } = props;

    const { newHwHeight } = useSelector((state) => state.user);
    const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);

    useEffect(() => {
        props.__Init(props.source, props.fetchOnFilter, props.reset);

        return () => {
            props.__Destroy();
        };
    }, []);

    useEffect(() => {
        if (flagUpdate) {
            __GetModel(source, fetchOnFilter);
        }
    }, [flagUpdate]);

    const headerActions = useMemo(() => {
        const array =
            actionsButton && actionsButton.length ? actionsButton : [];
        return array.map((action) => {
            const { title, Icon, onClick, newEqualizer, outOfMenu } = action;
            return {
                title,
                Icon,
                onClick,
                newEqualizer,
                outOfMenu,
            };
        });
    }, [actionsButton]);

    const filterModel = useMemo(() => {
        if (typeof typeTable === 'string' && typeTable === 'payments') {
            return [
                ...model,
                {
                    type: 'string',
                    code: 'email',
                    label: i18n.t('table.email'),
                },
                {
                    type: 'status_select',
                    code: 'status',
                    label: i18n.t('table.status'),
                },
            ];
        }

        return [...model];
    }, [typeTable, model]);

    const goldUser = useMemo(() => {
        return (
            props.HW.students_list &&
            props.HW.students_list.length &&
            props.HW.students_list[0].first_name === 'Group' &&
            props.HW.students_list[0].last_name === 'Group'
        );
    }, [props.HW.students_list, props.HW.student]);

    const mobile = usePhoneCheck();

    return typeTable === 'HW' || typeTable === 'journal' ? (
        <Paper className={'componentTable'}>
            <Form onSubmit={handleSubmit}>
                <Header
                    title={headerTable}
                    actions={!!model.length ? headerActions : []}
                />
                {mobile ? (
                    <div>
                        <Head
                            fetching={fetching}
                            typeTable={typeTable}
                            userData={userData}
                            data={data}
                            t={t}
                            __ChangeSort={(code) => __ChangeSort(source, code)}
                            order={sort.order}
                            orderBy={sort.orderBy}
                            model={model}
                            hasActions={!!actions.length}
                            goldUser={goldUser}
                        />
                        <Body
                            typeTable={typeTable}
                            fetching={fetching}
                            actions={actions}
                            rowsCount={rowsPerPage}
                            model={model}
                            userData={userData}
                            data={data}
                            payType={__typePayments}
                            paySum={__sumPayments}
                            goldUser={goldUser}
                            bankStudents={bankStudents}
                            bankStudentsVip={bankStudentsVip}
                        />
                    </div>
                ) : (
                    <div className={classes.tableWrapper}>
                        <Table>
                            <Head
                                fetching={fetching}
                                typeTable={typeTable}
                                userData={userData}
                                data={data}
                                t={t}
                                __ChangeSort={(code) =>
                                    __ChangeSort(source, code)
                                }
                                order={sort.order}
                                orderBy={sort.orderBy}
                                model={model}
                                hasActions={!!actions.length}
                                goldUser={goldUser}
                            />
                            <Body
                                typeTable={typeTable}
                                fetching={fetching}
                                actions={actions}
                                rowsCount={rowsPerPage}
                                model={model}
                                userData={userData}
                                data={data}
                                payType={__typePayments}
                                paySum={__sumPayments}
                                goldUser={goldUser}
                                bankStudents={bankStudents}
                                bankStudentsVip={bankStudentsVip}
                            />
                        </Table>
                    </div>
                )}

                <Box p={3}>
                    {(window.location.pathname.includes('/template')
                        ? !hideSubmitButton
                        : true) && callBackDataHW ? (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {props.HW.load ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                t('buttons.set')
                            )}
                        </Button>
                    ) : null}

                    {callBackDataHW && mobile && newHwHeight !== null ? (
                        <IconButton
                            aria-label="delete"
                            className="toPrewBlockButton"
                            onClick={() =>
                                setNewHwHeight()(store.dispatch, store.getState)
                            }
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    ) : null}
                </Box>
            </Form>
        </Paper>
    ) : (
        <Paper className={'componentTable'}>
            <div>
                <Header
                    title={headerTable}
                    filterActive={!!filter.length}
                    actions={!!model.length ? headerActions : []}
                />
                {enableFilter && !!model.length && (
                    <FilterView
                        filter={filter}
                        source={source}
                        onAccept={(newFilter) => {
                            __SetFilterValues()(newFilter);
                            __GetData(source, true, fetchOnFilter);
                        }}
                        model={filterModel}
                        cities={cities}
                        subdivisions={subdivisions}
                        counterparties={counterparties}
                        ordersStatusList={ordersStatusList}
                        expanded={expanded}
                        setFilterValues={__SetFilter()}
                        expandFilterView={__ExpandFilterView()}
                        rollUpFilterView={__RollUpFilterView()}
                        fetchOnFilter={props.fetchOnFilter}
                    />
                )}
                {mobile ? (
                    <div>
                        <Head
                            fetching={fetching}
                            typeTable={typeTable}
                            userData={userData}
                            data={data}
                            t={t}
                            __ChangeSort={(code) => __ChangeSort(source, code)}
                            order={sort.order}
                            orderBy={sort.orderBy}
                            model={model}
                            hasActions={!!actions.length}
                            goldUser={goldUser}
                        />
                        <Body
                            typeTable={typeTable}
                            fetching={fetching}
                            actions={actions}
                            rowsCount={rowsPerPage}
                            model={model}
                            userData={userData}
                            data={data}
                            payType={__typePayments}
                            paySum={__sumPayments}
                            goldUser={goldUser}
                            bankStudents={bankStudents}
                            bankStudentsVip={bankStudentsVip}
                        />
                    </div>
                ) : (
                    <div className={classes.tableWrapper}>
                        <Table stickyHeader>
                            <Head
                                fetching={fetching}
                                typeTable={typeTable}
                                userData={userData}
                                data={data}
                                t={t}
                                __ChangeSort={(code) =>
                                    __ChangeSort(source, code)
                                }
                                order={sort.order}
                                orderBy={sort.orderBy}
                                model={model}
                                hasActions={!!actions.length}
                                goldUser={goldUser}
                            />
                            <Body
                                typeTable={typeTable}
                                fetching={fetching}
                                actions={actions}
                                rowsCount={rowsPerPage}
                                model={model}
                                userData={userData}
                                data={data}
                                payType={__typePayments}
                                paySum={__sumPayments}
                                goldUser={goldUser}
                                bankStudents={bankStudents}
                                bankStudentsVip={bankStudentsVip}
                            />
                        </Table>
                    </div>
                )}
                {!isEmpty && !blockOptions && (
                    <Pagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={count}
                        t={t}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={__ChangePage(source)}
                        onRowsPerPageChange={__RowsPerPage(source)}
                        disabled={fetching}
                    />
                )}
            </div>
        </Paper>
    );
};

function initialValues(state) {
    const data =
        (state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.data) ||
        [];
    const initialData = {};
    for (let i in data) {
        for (var key in data[i]) {
            initialData[JSON.stringify({ code: key, id: data[i].id })] =
                data[i][key];
        }
    }
    return initialData;
}

const mapStateToProps = (state) => ({
    ...state.AsyncTable,
    initialValues: initialValues(state),
    user: state.user,
    HW: state.HW,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(ActionCreators, dispatch);
let withForm = styled(AsyncTable);

withForm = reduxForm({
    enableReinitialize: true,
    onSubmit,
    form: 'from-tableComponent', // a unique identifier for this form
})(withForm);

withForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withTranslation()(withForm);
