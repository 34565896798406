import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    getStatistics,
    removeStatistics,
    getArcards,
} from '../../actions/user';
import { withTranslation } from 'react-i18next';
import { openRequestModal } from '../../actions/modals';
import { getAllStatData } from '../../actions/hw';
import { getCitiesStat } from '../../actions/cities';

import {
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { Stack, Paper, IconButton } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CandleStickCharts from './../CandleStickCharts';

import '../../layout/Dash/Dash.css';
import UsersService from '../../services/users.service';
import { BRACELETS } from '../../constants/bracelet';
import logo_ru from '../../resources/img/logo/ru.png';
import logo_ua from '../../resources/img/logo/ua.png';
import logo_en from '../../resources/img/logo/en.png';
import logo_ee from '../../resources/img/logo/ее.png';
import logo_ro from '../../resources/img/logo/ro.png';
import logo_by from '../../resources/img/logo/by.png';
import { Bracelet } from '../ModalEditWindow/modalComponents/EditStudentBracelet';
import EditIcon from '@material-ui/icons/Edit';
import BalanceComponent from '../BalanceComponent';

const logo = {
    ru: logo_ru,
    ua: logo_ua,
    en: logo_en,
    ee: logo_ee,
    ro: logo_ro,
    by: logo_by,
    de: logo_en,
};

class DashStudentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arCardsList: null,
            puzzle: null,
            data: [],
            options: {},
            plugins: [],
            res: [],
            ready: [],
            currentBracelet: '',
            profile: {},
            choosen_shema: 0,
            id: this.props.user.emulation.length
                ? this.props.user.emulation[
                      this.props.user.emulation.length - 1
                  ].info.user_id
                : this.props.user.info.user_id,
        };

        this.props.getStatistics(false, { id: this.state.id });
    }

    async fetchUserProfile() {
        const profile = await UsersService.SingleUser(false, {
            id: this.state.id,
        });
        this.setState({
            profile,
            currentBracelet: BRACELETS.findIndex(
                (item) => item.label === profile.bracelet
            ),
        });
    }

    componentDidMount() {
        this.fetchUserProfile();
        UsersService.GetStatService();
        const uid = this.props.books.uid;
        if (uid) {
            const { t } = this.props;
            this.props.openRequestModal({
                opened: true,
                data: {
                    id: this.state.id,
                    header: t('groups.assign_book'),
                    modaltype: 'AssignBookModal',
                },
            });
        }
    }

    componentWillUnmount() {
        this.props.removeStatistics();
    }
    handleBookAssign = () => {
        const { t } = this.props;
        this.props.openRequestModal({
            opened: true,
            data: {
                id: this.state.id,
                header: t('groups.assign_book'),
                modaltype: 'AssignBookModal',
            },
        });
    };
    homeworkModal = () => {
        const { t } = this.props;
        const locate =
            document.location.hostname.includes('sap-test') ||
            document.location.hostname.includes('local');
        const domainLast =
            document.location.hostname.split('.')[
                document.location.hostname.split('.').length - 1
            ];
        const schema = {
            prod: `https://games.${
                domainLast === 'com' || domainLast === 'ee'
                    ? 'soroboom'
                    : 'soroban'
            }.${domainLast}`,
            test: `https://newalg.soroban.ua`,
        };
        this.props.openRequestModal({
            opened: true,
            data: {
                header: t('hw.title'),
                link: !locate ? schema.prod : schema.test,
                modaltype: 'HomeworkAcceptModal',
            },
        });
    };
    render() {
        const { t, i18n } = this.props;
        const { profile } = this.state;
        const roleId = this.props.user.info.role_id;
        return (
            <>
                <BalanceComponent />
                <div className="container-form">
                    <Stack spacing={2}>
                        <Stack
                            spacing={2}
                            flexWrap={'wrap'}
                            alignContent={'flex-start'}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.homeworkModal}
                            >
                                {t('hw.title')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleBookAssign}
                            >
                                {t('groups.assign_book')}
                            </Button>
                        </Stack>
                        <Accordion variant={'elevation'}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ width: '100%' }}
                            >
                                {t('groups.stat')}
                            </AccordionSummary>
                            <AccordionDetails style={{ overflow: 'auto' }}>
                                <Typography
                                    component="div"
                                    className="ar_cards"
                                >
                                    <CandleStickCharts
                                        data={{ id: this.state.id }}
                                    />
                                    ;
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Paper elevation={0} style={{ maxWidth: '300px' }}>
                            <Stack spacing={2} m={1} p={1}>
                                <Box
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Typography component="div">
                                        {t('bracelets.Current Bracelet')}
                                    </Typography>
                                    {false &&
                                        profile.first_name &&
                                        roleId !== 5 && (
                                            <IconButton
                                                onClick={() => {
                                                    this.props.openRequestModal(
                                                        {
                                                            opened: true,
                                                            data: {
                                                                id: profile.id,
                                                                header: `${t(
                                                                    'bracelets.Bracelet Control'
                                                                )} | ${
                                                                    profile.first_name
                                                                } ${
                                                                    profile.last_name
                                                                }`,
                                                                modaltype:
                                                                    'EditStudentBracelet',
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                <EditIcon fontSize={'small'} />
                                            </IconButton>
                                        )}
                                </Box>
                                <Stack spacing={1}>
                                    {BRACELETS.map((bracelet, i) => {
                                        return (
                                            <Bracelet
                                                key={i}
                                                i18n={i18n}
                                                bracelet={bracelet}
                                                logo={logo}
                                                style={{
                                                    opacity:
                                                        this.state
                                                            .currentBracelet < i
                                                            ? '0.4'
                                                            : '1',
                                                }}
                                            />
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
        users: state.users,
        books: state.books,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                getStatistics,
                removeStatistics,
                openRequestModal,
                getCitiesStat,
                getAllStatData,
                getArcards,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(DashStudentView));
