import Types from '../constants/supportChat';

const defaultState = {
    chatHistory: [],
    chats: [],
    activeChat: null, // ID активного чату
    totalNotViewed: null,
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.ADD_HISTORY_MESSAGE:
            // Перевіряємо, чи є чат з таким chat_id
            const existingChatIndex = state.chatHistory.findIndex(
                (chat) => chat.chatId === action.payload.chat_id
            );

            // Якщо чат знайдений, видаляємо його і додаємо новий
            if (existingChatIndex !== -1) {
                const updatedChatHistory = [...state.chatHistory];
                updatedChatHistory.splice(existingChatIndex, 1, {
                    // Видаляємо старий чат і додаємо новий
                    chatId: action.payload.chat_id,
                    messages: action.payload.messages,
                });
                return {
                    ...state,
                    chatHistory: updatedChatHistory,
                };
            } else {
                // Якщо чат не знайдений, додаємо новий чат
                return {
                    ...state,
                    chatHistory: [
                        ...state.chatHistory,
                        {
                            chatId: action.payload.chat_id,
                            messages: action.payload.messages,
                        },
                    ],
                };
            }

        case Types.ADD_NEW_MESSAGE:
            const chatIndex = state.chatHistory.findIndex(
                (chat) => chat.chatId === action.payload.chat_id
            );
            let updatedChats = [...state.chats];

            // Якщо чат з таким chat_id вже існує в chatHistory
            if (chatIndex !== -1) {
                // Створюємо копію chatHistory для оновлення
                const updatedChatHistory = [...state.chatHistory];
                // Перевіряємо, чи існує повідомлення з таким же message_id у чаті
                const messageExists = updatedChatHistory[
                    chatIndex
                ].messages.some(
                    (msg) =>
                        (msg.id || msg.messageId) === action.payload.message_id
                );

                // Якщо повідомлення з таким message_id ще немає, додаємо нове повідомлення
                if (!messageExists) {
                    updatedChatHistory[chatIndex].messages.push(
                        action.payload.message
                    );
                    updatedChats = updatedChats.map((chat) =>
                        chat.id === action.payload.chat_id
                            ? {
                                  ...chat,
                                  updated_at: action.payload.message.created_at,
                              }
                            : chat
                    );
                }

                // Повертаємо новий стан з оновленим chatHistory
                return {
                    ...state,
                    chatHistory: updatedChatHistory,
                    chats: updatedChats,
                };
            } else {
                // Якщо чат з таким chat_id ще не існує, створюємо новий чат та додаємо нове повідомлення
                return {
                    ...state,
                    chatHistory: [
                        ...state.chatHistory,
                        {
                            chatId: action.payload.chat_id,
                            messages: [action.payload.message],
                        },
                    ],
                    chats: updatedChats.map((chat) =>
                        chat.id === action.payload.chat_id
                            ? {
                                  ...chat,
                                  updated_at: action.payload.message.created_at,
                              }
                            : chat
                    ),
                };
            }

        case Types.CLOSE_TICKET:
            return {
                ...state,
                chatHistory: state.chatHistory.filter(
                    (chat) => chat.chatId !== action.payload.chat_id
                ),
            };

        case Types.SET_CHATS_BY_SUPPORT:
            return {
                ...state,
                isLoading: false, // После загрузки данных выключаем лоадер
                chats: action.payload,
            };

        case Types.SET_LOADING: // Добавим экшн для установки загрузки
            return {
                ...state,
                isLoading: action.payload,
            };

        case Types.SET_TOTAL_NOT_VIEWED_BY_CLIENT:
            return {
                ...state,
                totalNotViewed: action.payload, // оновлюємо значення totalNotViewed
                isLoading: false,
            };

        case Types.INCREMENT_CHAT_TOTAL_NOT_VIEWED:
            return {
                ...state,
                totalNotViewed: state.totalNotViewed + action.payload, // увеличиваем на 1
            };

        case Types.INCREMENT_CHAT_TOTAL_NOT_VIEWED_BY_SUPPORT:
            return {
                ...state,
                chats: state.chats.map((chat) =>
                    chat.user_id === action.payload
                        ? {
                              ...chat,
                              counterNotViewed:
                                  (chat.counterNotViewed || 0) + 1,
                          }
                        : chat
                ),
            };

        case 'SET_ACTIVE_CHAT':
            return {
                ...state,
                activeChat: action.payload,
            };

        case 'CLEAR_ACTIVE_CHAT':
            return {
                ...state,
                activeChat: null,
            };

        default:
            return state;
    }
};
