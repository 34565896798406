import * as React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

export default function UpdateComponent() {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [updateAvailable, setUpdateAvailable] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        navigator.serviceWorker?.getRegistration().then((registration) => {
            if (registration?.waiting) {
                setUpdateAvailable(true);
                setOpen(true);
            }

            registration?.addEventListener('updatefound', () => {
                const newWorker = registration.installing;
                newWorker?.addEventListener('statechange', () => {
                    if (
                        newWorker.state === 'installed' &&
                        navigator.serviceWorker.controller
                    ) {
                        setUpdateAvailable(true);
                        setOpen(true);
                    }
                });
            });
        });

        const handleServiceWorkerMessage = (event) => {
            console.log(event);
            if (event.data && event.data.type === 'RELOAD_PAGE') {
                window.location.reload();
            }
        };

        navigator.serviceWorker?.addEventListener(
            'message',
            handleServiceWorkerMessage
        );

        return () => {
            navigator.serviceWorker?.removeEventListener(
                'message',
                handleServiceWorkerMessage
            );
        };
    }, []);

    const handleUpdate = () => {
        navigator.serviceWorker
            .getRegistration()
            .then((registration) => {
                if (!registration) {
                    console.log('Service Worker не найден.');
                    return;
                }

                if (registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }
            })
            .catch((error) => {
                console.error(
                    'Ошибка получения регистрации Service Worker:',
                    error
                );
            });
    };

    return (
        <div>
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <SnackbarContent
                    sx={{ backgroundColor: '#78ba98', color: '#fff' }}
                    message={
                        <>
                            <Typography variant="h6">
                                {t('notifications.updateAlaviable')}
                            </Typography>
                            <Typography
                                variant="caption"
                                style={{
                                    color: 'rgb(199 86 10)',
                                    fontWeight: '500',
                                    width: '300px',
                                }}
                            >
                                {t('notifications.updateWarning')}
                            </Typography>
                        </>
                    }
                    action={
                        <>
                            <Button
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleUpdate}
                                endIcon={<UpdateIcon />}
                            >
                                {t('notifications.update')}
                            </Button>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </>
                    }
                />
            </Snackbar>
        </div>
    );
}
