export const filterData = (data, filters, transformStatus) => {
    return data.filter(row => {
        return Object.entries(filters).every(([key, value]) => {
            if (!value) return true; 
            
            if (key === "status") {
                return transformStatus 
                    ? transformStatus(row[key]) === value 
                    : row[key] === value;
            };

            if (key === "group") {
                if (typeof row[key] === 'object') {
                    return Object.values(row[key]).some(groupValue => 
                        groupValue !== null && groupValue !== undefined && 
                        String(groupValue).toLowerCase() === String(value).toLowerCase()
                    );
                }
                return String(row[key]).toLowerCase() === String(value).toLowerCase();
            };

            // Приводимо значення до рядка для коректного пошуку
            const rowValue = row[key] !== undefined && row[key] !== null ? String(row[key]) : "";
            const filterValue = String(value);

            return rowValue.toLowerCase().includes(filterValue.toLowerCase());
        });
    });
};
