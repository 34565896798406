// eslint-disable-next-line
import { ApiConnector, GET, POST, PUT, DELETE } from "./requestV2.service";
export default (() => {
  const API = ApiConnector("/videohelper");
  return {
    GetAllHelpers: async (query) => await API(GET, "", false, query).call(),
    GetRoleHelper: async (query) => await API(GET, "/tutorial", false, query).call(),
    GetRoleHelperPermissions: async (query) => await API(GET, "/permission", false, query).call(),
    CreateHelper: async (data) => await API(POST, "").call(data),
    EditHelper: async (data) => await API(PUT, "").call(data),
    DeleteHelper: async (data) => await API(DELETE, "", false, data).call(data),
  };
})();
