import store from './../store';
import Auth from './auth.service';
import { emulation, resume } from './../actions/user';
import Types from '../constants/user';

class TokenService {
    constructor() {
        if (TokenService.instance) {
            return TokenService.instance;
        }

        this.isRefreshing = false;
        this.isRefreshingEmulation = false;
        this.refreshPromise = null;
        this.refreshEmulationPromise = null;

        TokenService.instance = this;
        return this;
    }

    async refreshAccessToken(retryRequest) {
        if (this.isRefreshing) {
            return this.refreshPromise
                .then(() => retryRequest())
                .catch((error) => {
                    console.error(
                        'Error during retryRequest execution:',
                        error
                    );
                    throw error;
                });
        }

        this.isRefreshing = true;
        const refresh_token = store.getState().user.refresh_token;

        this.refreshPromise = Auth.Resume({ refresh_token })
            .then((response) => {
                store.dispatch(resume(response));
                return response.access_token;
            })
            .catch((error) => {
                console.error('Error refreshing token:', error);
                store.dispatch({ type: Types.LOGOUT });
                throw error;
            })
            .finally(() => {
                this.isRefreshing = false;
                this.refreshPromise = null;
            });

        try {
            await this.refreshPromise;
            return retryRequest();
        } catch (error) {
            console.error('Error in refreshAccessToken final retry:', error);
            throw error;
        }
    }

    async refreshEmulationToken(retryRequest) {
        if (this.isRefreshingEmulation) {
            return this.refreshEmulationPromise.then(() => retryRequest());
        }

        this.isRefreshingEmulation = true;
        const store = store.getState();

        this.refreshEmulationPromise = await emulation({
            id: store.user.emulation[store.user.emulation.length - 1].user_id,
        })(store.dispatch, store.getState)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                store.dispatch({ type: Types.LOGOUT });
                throw error;
            })
            .finally(() => {
                this.isRefreshingEmulation = false;
                this.isRefreshingEmulation = null;
            });

        return this.refreshEmulationPromise;
    }
}

export const tokenService = new TokenService();
