export default {
  INIT: "@__INIT_ASYNC_TABLE",
  DESTROY: "@__DESTROY_ASYNC_TABLE",
  ROWS_PER_PAGE: "@__ROWS_PER_PAGE_ASYNC_TABLE",
  CHANGE_PAGE: "@__CHANGE_PAGE_ASYNC_TABLE",
  MODEL: "@__MODEL_ASYNC_TABLE",
  MODEL_UPDATE: "@__MODEL_UPDATE_ASYNC_TABLE",
  DATA: "@__DATA_ASYNC_TABLE",
  TOOGLE_SETTINGS: "@__TOOGLE_SETTINGS_ASYNC_TABLE",
  TOOGLE_FILTER: "@__TOOGLE_FILTER_ASYNC_TABLE",
  SET_FILTER: "@__SET_FILTER_ASYNC_TABLE",
  SORT: "@__CHANGE_SORT",
  CITIES: "@__CITIES_ASYNC_TABLE",
  SUBDIVISIONS: "@__SUBDIVISIONS_ASYNC_TABLE",
  COUNTERPARTIES: "@__COUNTERPARTIES_ASYNC_TABLE",
  ORDERS_STATUS_LIST: "@__ORDERS_STATUS_LIST_ASYNC_TABLE",
  TOOGLE_FILTER_VIEW: "@__TOOGLE_FILTER_VIEW_ASYNC_TABLE",
  ADD_DATA_HW_ROW: "@__ADD_DATA_HW_ROW",
  INIT_DATA_HW_ROW: "@__INIT_DATA_HW_ROW",
  SETSOURCE: "@__SET_SOURCE",
  PAY_TYPE: "@payment_type",
  PAY_SUM: "@payment_sum",
  ACTIVATE_FETCHING: "@activate_skeleton",
  SELECTMULT: "@select_mult_theme",
  CLEAR_TABLE_DATA: "CLEAR_TABLE_DATA",
};
