import { ApiConnector, GET, POST, PUT, DELETE } from "./requestV2.service";

export default (() => {
  const API = ApiConnector("/fop");
  return {
    CreateFopBill: async (data) => await API(POST, "", false, false).call(data),
    EditFopBill: async (data) => await API(PUT, "", false, false).call(data),
    GetFopBillList: async (data) => await API(GET, "", false, false).call(data),
    DeleteFopBill: async (query) => await API(DELETE, "", false, query).call(),
  };
})();
