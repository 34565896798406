import logo_ru from '../resources/img/logo/ru.png';
import logo_ua from '../resources/img/logo/ua.png';
import logo_en from '../resources/img/logo/en.png';
import logo_ee from '../resources/img/logo/ее.png';
import logo_ro from '../resources/img/logo/ro.png';
import logo_by from '../resources/img/logo/by.png';
import logo_cz from '../resources/img/logo/en.png';

const logo = {
    ru: logo_ru,
    ua: logo_ua,
    en: logo_en,
    ee: logo_ee,
    ro: logo_ro,
    by: logo_by,
    de: logo_en,
    cz: logo_cz,
    pl: logo_en,
};

export default logo;
