// eslint-disable-next-line
import { ApiConnector, GET, POST, DELETE } from "./requestV2.service";


export default (() => {
  const API = ApiConnector("/models", true);
  return {
    HomeworkList: async (data) =>
          await API(GET, "/homework", false, data).call(false),
  };
})();

