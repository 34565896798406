import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { reduxForm, Field, initialize } from 'redux-form';
import { useParams } from 'react-router-dom';
import { setBalance, setToken } from '../../actions/bank';
import { getError, getSuccess } from '../../actions/toast';
import { openRequestModal } from '../../actions/modals';
import BankCustomersService from '../../services/bank-customers.service';
import BankTransactionsService from '../../services/bank-transactions.service';
import { useTranslation } from 'react-i18next';
import {
    TextField,
    Stack,
    Paper,
    Box,
    Typography,
    Collapse,
} from '@mui/material';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import BackButton from '../../components/BalanceComponent/BackButton';
import renderTextFieldSorocoinsAmount from '../../components/FormComponents/TextFieldSorocoinsAmount';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';


const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
});

const renderTextFieldCardNumber = ({ input, meta, ...custom }) => (
    <TextField
        {...input}
        {...custom}
        onChange={(e) => {
            const value = e.target.value;

            // Дозволити лише цифри
            const formattedValue = value.replace(/[^0-9]/g, '');

            // Обмежити до 16 символів
            if (formattedValue.length <= 16) {
                input.onChange(formattedValue);
            }
        }}
    />
);


function UserBankSorobanPage(props) {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [userBalance, setUserBalance] = useState('0');
    const [cardNumber, setCardNumber] = useState('');
    const [noBankError, setBankError] = useState('');
    const [isInvalidBalance, setIsInvalidBalance] = useState(false);
    const [isInvalidCardNumber, setIsInvalidCardNumber] = useState(false);

    const { classes } = props;
    const { t } = useTranslation();
    const { id, role } = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;

    const getCheckExist = async () => {
        const res = await BankCustomersService.GetCheckExist();
        if (res && res.jwt) {
            dispatch(setToken(res));
            dispatch(setBalance());
        }
    };

    const fetchPaymentInfo = async () => {
        const res = await BankCustomersService.GetPaymentInfo(id);

        if (Object.keys(res).length) {
            setName(res.first_name);
            setSurname(res.last_name);
            setCardNumber(res.cardNumber);
            setUserBalance(res.balance);
        } else {
            setBankError(t('userBankSorobanPage.setBankError'));
        }
    };

    const fetchBalance = async () => {
        dispatch(setBalance());
    };

    useEffect(() => {
        getCheckExist();
        fetchPaymentInfo();
        fetchBalance();
    }, []);

    function handleClick() {
        fetchBalance();
    }

    // Ініціалізація initialValues для форми
    // підставляємо у поле номер картки щоб не вводити вручну
    useEffect(() => {
        props.initialize({
            cardNumber: cardNumber || '',
            sorocoinsAmount: '',
        });
    }, [props.initialize, cardNumber]);

    async function handleSubmit(formData) {
        const enteredAmount = parseFloat(formData.sorocoinsAmount);
        const normalizedAmount = formData.sorocoinsAmount.replace(',', '.');
        const cleanValueСardNumber = formData.cardNumber.replace(/\s+/g, '').trim();
        // Додаємо пробіли кожні 4 цифри
        const formattedValueСardNumber = cleanValueСardNumber.match(/.{1,4}/g)?.join(' ') || '';

        if (parseFloat(enteredAmount) <= parseFloat(balance)) {
            const data = {
                amount: parseFloat(normalizedAmount),
                cardNumber: formattedValueСardNumber,
                note: '',
            };

            try {
                await BankTransactionsService.TransferToCard(data);
                setTimeout(fetchBalance, 50);
                setIsInvalidBalance(false);
                fetchPaymentInfo();
                props.reset('userBankSorobanForm');
                getSuccess();  
                
            } catch (error) {
                if (error.message === 'Card number does not exist') {
                    getError(error);
                    setIsInvalidCardNumber(true);
                }
            }

        } else {
            toast.error(t('userBankSorobanPage.notEnoughMoney'));
            setIsInvalidBalance(true);
        }
    }

    const handleOpenRequestModal = (data) => {
        dispatch(
            openRequestModal({
                opened: true,
                data,
            })
        );
    };

    return (
        <>
            {role === 'user'
                ? <BackButton to={`/users`} label={t('userBankSorobanPage.users')} />
                : <BackButton to={`/dashboard/balances/${role}`} label={t('userBankSorobanPage.balances')} />
            }
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>SOROBANK</Typography>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                >
                    <Tooltip
                        title={t('userBankSorobanPage.updateBalance')}
                        placement="bottom"
                    >
                        <IconButton
                            onClick={handleClick}
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '20px',
                                marginRight: '12px',
                            }}
                            aria-label={t('userBankSorobanPage.updateBalance')}
                        >
                            <UpdateIcon style={{ color: '#58a315' }} />
                        </IconButton>
                    </Tooltip>
                    <div className={classes.flexContainer}>
                        <Typography style={{ marginRight: '12px' }}>
                            {t('userBankSorobanPage.yourBalance')}{' '}
                        </Typography>
                        <span style={{ color: '#44A340' }}>
                            {balance} SoroCoins
                        </span>
                    </div>
                </Stack>
            </Stack>

            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <Collapse in={!!noBankError}>
                        <Typography
                            m={2}
                            ml={0}
                            color={'error'}
                            variant={'caption'}
                            style={{ fontSize: '18px' }}
                        >
                            {noBankError}
                        </Typography>
                    </Collapse>
                    <div className={classes.flexContainer}>
                        <div className={classes.flexContainer}>
                            <Typography style={{ marginRight: "10px" }}>
                                {name} {surname}
                            </Typography>
                            <Stack direction="row">
                                <Tooltip
                                    title={t('transactionsHistory.modalTitleTransactionsHistory')}
                                    placement="bottom"
                                >
                                    <IconButton
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '20px',
                                        }}
                                        aria-label={t('transactionsHistory.modalTitleTransactionsHistory')}
                                        onClick={() => {
                                            handleOpenRequestModal({
                                                header: t('transactionsHistory.modalTitleTransactionsHistory'),
                                                modaltype: 'TransactionHistory',
                                            });
                                        }}
                                    >
                                        <ReceiptLongIcon
                                        // style={{ color: '#58a315' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={t('transactionsHistory.modalTitleOrderHistory')}
                                    placement="bottom"
                                >
                                    <IconButton
                                        // onClick={handleClick}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '20px',
                                        }}
                                        aria-label={t('transactionsHistory.modalTitleOrderHistory')}
                                        onClick={() => {
                                            handleOpenRequestModal({
                                                // id: userId,
                                                header: t('transactionsHistory.modalTitleOrderHistory'),
                                                modaltype: 'OrderHistory',
                                            });
                                        }}
                                    >
                                        <ShoppingBagIcon
                                            style={{ color: '#58a315' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                        <Stack direction="row">
                            <Typography
                                align="right"
                                style={{ marginRight: '16px' }}
                            >
                                {t('userBankSorobanPage.balance')}
                                <span
                                    style={{
                                        display: 'inline',
                                        marginLeft: '5px',
                                        color: '#44A340',
                                        textAlign: 'right',
                                    }}
                                >
                                    {userBalance || 0} SoroCoins
                                </span>
                            </Typography>
                            <Typography align="right">id={id}</Typography>
                        </Stack>
                    </div>
                </Box>
            </Paper>
            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <form
                        onSubmit={props.handleSubmit(handleSubmit)}
                        autoComplete="off"
                    >
                        <Stack spacing={3}>
                            <Typography>
                                {t('userBankSorobanPage.transferToAccount')}
                            </Typography>
                            <Field
                                name="cardNumber"
                                label={t('userBankSorobanPage.cardNumber')}
                                style={{
                                    color: '#00A10C',
                                    width: '280px',
                                }}
                                variant="outlined"
                                component={renderTextFieldCardNumber}
                                required
                                error={isInvalidCardNumber}
                            />
                            <Field
                                name="sorocoinsAmount"
                                label={t('userBankSorobanPage.quantity')}
                                style={{
                                    color: '#00A10C',
                                    width: '280px',
                                }}
                                variant="outlined"
                                component={renderTextFieldSorocoinsAmount}
                                required
                                error={isInvalidBalance}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '250px' }}
                                type="submit"
                            >
                                {t('userBankSorobanPage.transferSorocoins')}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Paper>
        </>
    );
}

// Валідація форми
const validate = (values) => {
    const errors = {};

    if (!values.cardNumber) {
        errors.cardNumber = 'required field';
    }

    if (!values.sorocoinsAmount) {
        errors.sorocoinsAmount = 'required field';
    }

    return errors;
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const StyleUserBankSorobanPage = withStyles(styles)(UserBankSorobanPage);

const ConnectedReduxForm = reduxForm({
    form: 'userBankSorobanForm',
    validate,
})(StyleUserBankSorobanPage);


export default connect(mapStateToProps)(ConnectedReduxForm);

