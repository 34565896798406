import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from './../../store';
import { openRequestModal } from '../../actions/modals';
import { setAllCartItems } from '../../actions/bank';
import { filterData } from "../../actions/filterData";
import BankProductsService from '../../services/bank-products.service';
import BankCartService from '../../services/bank-cart.service';
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import {
    Grid,
    Card,
    Typography,
    CardContent,
    CardMedia,
    Button,
    CardActionArea,
    CardActions,
    Chip,
    Stack,
    Badge,
    Box,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'transparent',
        color: '#43a340',
        fontSize: '11px',
        fontWeight: 'bold',
        top: '0px',
        right: '8px',
    },
    '& .cardItem': {
        width: 300,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
}));

export default function StudentStorefrontComponent(props) {
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({ title: "" });
    const [initialRows, setInitialRows] = useState([]);
    const { t } = useTranslation();
    const roleId = useSelector((state) => state.user.info.role_id);
    const dispatch = useDispatch();
    const { emulation } = store.getState().user;
    const productsInCart = useSelector((state) => state.bank.cart) || [];
    const user = useSelector((state) => state.bank);
    const hasEmulation =
        emulation.length && emulation[emulation.length - 1].info;

    // отримати всі товари кошика і записати в стейт
    const fetchProductsAllCart = async () => {
        // if (roleId === 5) {
        if (roleId === 5 || hasEmulation.role_id === 5) {
            await dispatch(setAllCartItems());
        } else return;
    };

    useEffect(() => {
        fetchProductsAllCart();
    }, []);

    // отримати лише активні товари товари
    const getActiveProducts = async () => {
        const res = await BankProductsService.GetActiveProducts();

        const products = res
            ? res.map((item) => ({
                  id: item.id,
                  title: item.title,
                  amount: item.amount || 0,
                  price: item.price,
                  vip: item.vip,
                  img: item.img,
                  promo: item.promo,
                  detail: item.detail,
              }))
            : [];
        setProducts(products);
        setInitialRows(products);
    };

    useEffect(() => {
        getActiveProducts();
    }, []);

    useEffect(() => {
        const checkProductAvailability = () => {
            const newProducts = products.map((product) => {
                if (product.amount === 0) {
                    return { ...product, transparent: true };
                } else {
                    return { ...product, transparent: false };
                }
            });

            if (JSON.stringify(newProducts) !== JSON.stringify(products)) {
                setProducts(newProducts);
            }
        };
        checkProductAvailability();
    }, [products]);

    const handleOpenProductDetailsModal = (productId) => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: productId,
                    header: t(
                        'studentStorefrontComponent.titleModalProductDetails'
                    ),
                    modaltype: 'ProductDetails',
                },
            })
        );
    };

    const handleClickAddToCart = async (productId) => {
        // Якщо товар вже є у кошику виходимо з функції
        if (productsInCart.find((item) => item.product.id === productId)) {
            return;
        }
        const addProductToCart = async () => {
            const res = await BankCartService.AddItemToCart({
                productId: productId,
                quantity: 1,
            });
        };
        await addProductToCart();
        await fetchProductsAllCart();
    };

    const handleFilterChange = (key, value) => {
        const newFilters = { ...filters, [key]: value };
        setFilters(newFilters);
        setProducts(filterData(initialRows, newFilters));
    };
        
    const filterConfigs = [
        { label: t('storefrontComponent.productName'), key: "title" },
    ];

    return (
        <>
            <Stack
                justifyContent="flex-start"
                spacing={2}
                style={{ marginTop: '8px', padding: '14px' }}
            >
                {filterConfigs.map(({ label, key, transform }) => (
                    <CustomAutocomplete
                        key={key}
                        label={label}
                        options={[...new Set(products.map(row => (transform ? transform(row[key]) : row[key]) || ""))]}
                        inputValue={filters[key] || ""}
                        onChange={(e, newValue) => handleFilterChange(key, newValue || "")}
                        onInputChange={(e, newInputValue) => setFilters(prev => ({ ...prev, [key]: newInputValue || "" }))}
                    />
                ))}
            </Stack>
            <Grid
                container
                justifyContent="flex-start"
                sx={{ marginTop: '30px', backgroundColor: 'transparent' }}
            >
                {products
                    .toSorted((a, b) => a.promo - b.promo)
                    .map((product) => {
                        const truncatedDescription =
                            product.detail.length > 85
                                ? product.detail.substring(0, 85) + '...'
                                : product.detail;
                        return (
                            <Card
                                key={product.id}
                                sx={{
                                    width: { xs: '100%', md: 230 },
                                    margin: '10px',
                                    border: '1px solid #ddd',
                                    opacity: 11,
                                    boxShadow:
                                        '0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image={product.img}
                                        alt={product.title}
                                        style={{
                                            borderRadius: '16px',
                                            padding: '10px',
                                        }}
                                    />
                                    <Box
                                        onClick={() =>
                                            handleOpenProductDetailsModal(
                                                product.id
                                            )
                                        }
                                        style={{
                                            flexDirection: 'column',
                                            flex: '1 0 auto',
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                            }}
                                            pl={2}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h6"
                                                component="div"
                                                style={{
                                                    width: '170px',
                                                    textAlign: 'left',
                                                    lineHeight: '23px',
                                                    fontWeight: 400,
                                                    fontSize:
                                                        product.title.length > 30
                                                            ? '13px'
                                                            : '16px',
                                                    height: '45px',
                                                }}
                                            >
                                                {product.title}
                                            </Typography>
                                        </Box>
                                        <CardContent
                                            style={{
                                                flexGrow: 1,
                                                padding: 10,
                                                width: '100%',
                                            }}
                                        >
                                            <Stack
                                                sx={{
                                                    flexDirection: 'row',
                                                    justifyContent: {
                                                        xs: 'flex-start',
                                                        md: 'space-between',
                                                    },
                                                    alignItems: 'center',
                                                    marginBottom: 1,
                                                }}
                                                spacing={1}
                                                direction={'row'}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    style={{
                                                        fontWeight: '100',
                                                        margin: '0',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: '700',
                                                            marginRight: '5px',
                                                        }}
                                                    >
                                                        {product.price}
                                                    </span>
                                                    SBK
                                                </Typography>
                                                {+product.price !==
                                                    +product.vip && (
                                                        <StyledBadge
                                                            color="transparent"
                                                            badgeContent={'VIP'}
                                                        >
                                                            <Chip
                                                                style={{
                                                                    color: 'black',
                                                                    fontWeight: 600,
                                                                }}
                                                                label={
                                                                    <Typography
                                                                        gutterBottom
                                                                        variant="h6"
                                                                        component="div"
                                                                        style={{
                                                                            fontWeight:
                                                                                '100',
                                                                            margin: '0',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    '700',
                                                                                marginRight:
                                                                                    '5px',
                                                                                color: '#43a340',
                                                                            }}
                                                                        >
                                                                            {
                                                                                product.vip
                                                                            }
                                                                        </span>
                                                                        SBK
                                                                    </Typography>
                                                                }
                                                            />
                                                        </StyledBadge>
                                                    )}
                                            </Stack>
                                            <Box pt={1} pb={1}>
                                                <Box>
                                                    <Typography variant="caption">
                                                        {t('table.note')}:
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    variant="caption"
                                                    color="text.secondary"
                                                    style={{
                                                        width: '170px',
                                                        height: '66px',
                                                    }}
                                                >
                                                    {truncatedDescription}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Box>
                                </div>
                                {/* {roleId === 5 && !emulation.length && ( */}
                                {(roleId === 5 || hasEmulation.role_id === 5) &&
                                    user.customer.role_id === 5 && (
                                        <CardActions
                                            style={{ justifyContent: 'flex-end' }}
                                        >
                                            {product.amount === 0 ? (
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    style={{
                                                        textTransform: '',
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {t(
                                                        'studentStorefrontComponent.notAvailable'
                                                    )}
                                                </Typography>
                                            ) : (
                                                <Button
                                                    style={{ color: '#00AB55' }}
                                                    onClick={() =>
                                                        handleClickAddToCart(
                                                            product.id
                                                        )
                                                    }
                                                    size="small"
                                                    fullWidth
                                                >
                                                    {productsInCart.find(
                                                        (item) =>
                                                            item.product.id ===
                                                            product.id
                                                    )
                                                        ? t(
                                                            'studentStorefrontComponent.inBasket'
                                                        )
                                                        : t(
                                                            'studentStorefrontComponent.addToBasket'
                                                        )}
                                                </Button>
                                            )}
                                        </CardActions>
                                    )}
                            </Card>
                        );
                    })}
            </Grid>
        </>
    );
}
