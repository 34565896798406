import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';


// Стилізований Popper для темної теми
const CustomPopper = styled(Popper)({
    '& .MuiAutocomplete-paper': {
        backgroundColor: '#424242',
        color: '#ffffff',
    },
    '& .MuiAutocomplete-option': {
        '&.Mui-selected': {
            backgroundColor: '#616161',
        },
        '&:hover': {
            backgroundColor: '#757575',
        },
    },
});
const width = 260;

const CustomAutocomplete = ({
    options,
    label,
    inputValue,
    onInputChange,
    onChange,
    ...props
}) => {
    const { t } = useTranslation();
    const themeMode = useSelector((state) => state.user.theme);
    const popperComponent =
        themeMode === 'dark'
            ? (props) => <CustomPopper {...props} />
            : undefined;

    const [error, setError] = useState(false);

    const handleInputChange = (event, newInputValue) => {
        onInputChange(event, newInputValue);
        const isMatch = options.some(option =>
            option.toLowerCase().includes(newInputValue.toLowerCase())
        );
        setError(newInputValue.length > 0 && !isMatch);
    };

    return (
        <Autocomplete
            freeSolo
            sx={{
                width: width,
                marginBottom: '10px',
                '& .MuiIconButton-root': { padding: 0 },
            }}
            PopperComponent={popperComponent}
            options={options}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={handleInputChange} 
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={error}
                    helperText={error ? t("activeTransactions.filteringError") : ""} 
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '& .MuiAutocomplete-input': {
                                paddingRight: '20px!important',
                            },
                        },
                    }}
                />
            )}
            {...props}
        />
    );
};

export default CustomAutocomplete;
