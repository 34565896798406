// eslint-disable-next-line
import { ApiConnector, GET, POST } from "./requestV2.service";
export default (() => {
  const API = ApiConnector("/analitic");
  return {
    GelFranchData: async (data) =>
      await API(GET, "/franch", false, data).call(),
      GelFinanceData: async (data) =>
        await API(GET, "/finance", false, data).call(),
    GetStatReport: async (data) =>
      await API(
        GET,
        `/office-teacher/status?school=${data.school}&teacher=${data.teacher}&status=${data.status}`
      ).call(),
  };
})();
