import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import ru from './locales/ru';
import ua from './locales/ua';
import ee from './locales/ee';
import ro from './locales/ro';
import by from './locales/by';
import de from './locales/de';
import cz from './locales/cz';
import pl from './locales/pl';

let domain =
    document.location.hostname.split('.')[
        document.location.hostname.split('.').length - 1
    ];

const schema = {
    by: { by, ru },
    ua: { ua, ru },
    ee: { ee, ru },
    ru: { ru },
    com: { en },
    ro: { ro, ru },
    de: { de },
    cz: { cz, en },
    pl: { pl, ua },
};
if (!isNaN(+domain)) domain = 'ua';
if (domain === 'md') domain = 'ro';
i18n.use(initReactI18next).init({
    resources: schema[domain],
    lng: domain === 'com' ? 'en' : domain,
    interpolation: {
        escapeValue: false,
    },
});

i18n.changeLanguage(
    !localStorage.getItem('lang')
        ? domain === 'com'
            ? 'en'
            : domain
        : localStorage.getItem('lang')
);
export default i18n;
