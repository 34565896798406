import React from "react";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Field } from "redux-form";
import Checkbox from "@material-ui/core/Checkbox";
import store from "./../../../../store";

const renderTextField = ({
  input,
  meta: { touched, error },
  role_id,
  ...custom
}) => {
  const hideSubmitButton = useSelector((state) => state.HW.hideSubmitButton);
  return (
    <Checkbox
      {...input}
      {...custom}
      disabled={(role_id !== 2 && role_id !== 4) || (location.pathname.includes("/template") && hideSubmitButton)}
      checked={!!input.value}
      inputProps={{ name: input.name }}
    />
  );
  // ChangeStudentGroup
};
const сheckbox =  ({ value, code, id }) => {
  if (!value) value = false;
  const user = store.getState().user;

  const role_id = user.emulation.length
    ? user.emulation[user.emulation.length - 1].info.role_id
    : user.info.role_id;
  return (
    <Field
      name={JSON.stringify({
        code: code === "can_edit" && role_id === 4 ? "do_delete" : code,
        id,
      })}
      component={renderTextField}
      role_id={role_id}
    />
  );
};

export default withRouter(сheckbox);
