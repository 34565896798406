import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Form, reduxForm } from 'redux-form';
import uuid from 'uuid/v4';

import FilterViewMapper from './FilterViewMapper';

import onSubmit from './onSubmit';
import validate from './validate';

import { Box, Stack } from '@mui/material';
import { withTranslation } from 'react-i18next';

const styled = withStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        backgroundColor: theme.palette.grey[100],
        '&:before': {
            opacity: 0,
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    details: {
        padding: 0,
    },
    list: {
        width: '100%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: 0,
        marginBottom: 0,
    },
    listItem: {
        width: '100%',
    },
}));

class View extends React.Component {
    componentDidMount() {
        const { initialize, filter } = this.props;
        initialize(
            filter.reduce(
                (acc, { values, code }) =>
                    !values ? acc : { ...acc, [code]: values },
                {}
            )
        );
    }
    handleAddFilter = (data) => {
        this.props.setFilterValues(data);
    };

    render() {
        const {
            expanded,
            handleSubmit,
            classes,
            filter,
            onAccept,
            expandFilterView,
            rollUpFilterView,
            model,
            fetchOnFilter,
        } = this.props;
        const { t } = this.props;
        return (
            <Form name="filter_form" onSubmit={handleSubmit}>
                <Accordion
                    expanded={expanded}
                    onChange={expanded ? rollUpFilterView : expandFilterView}
                    className={`${classes.root} filter_form`}
                >
                    <AccordionSummary
                        expandIcon={
                            <Tooltip
                                placement="top"
                                title={
                                    expanded
                                        ? t('text.collapse')
                                        : t('text.expand')
                                }
                            >
                                {!expanded ? (
                                    <Fade in>
                                        <ExpandMoreIcon />
                                    </Fade>
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </Tooltip>
                        }
                    >
                        <Badge
                            badgeContent={filter.length}
                            style={{
                                marginLeft: '20px',
                                width: expanded ? '170px' : '70px',
                            }}
                            color="primary"
                        >
                            <Typography
                                color="primary"
                                className={classes.heading}
                            >
                                {expanded
                                    ? t('text.filtration_rules')
                                    : t('text.filter_on')}
                            </Typography>
                        </Badge>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <Grid className={classes.list} container spacing={2}>
                            {filter.map((item, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    key={`${index}_${filter.length}`}
                                    className={classes.listItem}
                                >
                                    <FilterViewMapper
                                        item={item}
                                        mindex={index}
                                        unique={filter}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Stack direction={'column'} spacing={1} ml={3} mb={2}>
                            <Box
                                style={{
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    justifyContent: 'flext-start',
                                    flexDirection: 'row',
                                }}
                            >
                                {model.slice(1, 99).map((data, index) => {
                                    let activeFilter = false;
                                    for (let f of filter) {
                                        if (f.code === data.code)
                                            activeFilter = true;
                                    }
                                    return data.filter !== false ? (
                                        activeFilter ? (
                                            <Chip
                                                style={{
                                                    marginTop:
                                                        'unset!important',
                                                    marginBottom: '7px',
                                                    marginRight: '7px',
                                                }}
                                                key={index}
                                                color="primary"
                                                onDelete={() =>
                                                    this.handleAddFilter(data)
                                                }
                                                onClick={() =>
                                                    this.handleAddFilter(data)
                                                }
                                                label={t(data.label)}
                                            />
                                        ) : (
                                            <Chip
                                                style={{
                                                    marginTop:
                                                        'unset!important',
                                                    marginBottom: '7px',
                                                    marginRight: '7px',
                                                }}
                                                key={index}
                                                onClick={() =>
                                                    this.handleAddFilter(data)
                                                }
                                                label={t(data.label)}
                                            />
                                        )
                                    ) : null;
                                })}
                            </Box>
                            <Stack
                                direction={'row'}
                                spacing={1}
                                ml={1}
                                style={{
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Button
                                    className={'filterButtons'}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={fetchOnFilter && !filter.length}
                                >
                                    {t('buttons.apply')}
                                </Button>
                                <Button
                                    className={'filterButtons clear'}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        onAccept([]);
                                        this.props.reset();
                                    }}
                                >
                                    {t('buttons.reset')}
                                </Button>
                            </Stack>
                        </Stack>
                    </AccordionActions>
                </Accordion>
            </Form>
        );
    }
}

const withForm = reduxForm({
    form: 'filter-' + uuid(),
    updateUnregisteredFields: true,
    onSubmit,
    validate,
});

export default withTranslation()(withForm(styled(View)));
