import { ApiConnector, GET } from './requestV2.service';

export default (() => {
    const API = ApiConnector('/currencies');
    return {
        GetCurrency: async (query) =>
            await API(GET, '/country', false, query).call(),
        CurrenciesList: async (query) => await API(GET, '').call(),
    };
})();
