export default {
  SUPERADMIN: "Супер админ",
  BOSS: "Босс",
  FRANCHISE: "Франчайзи",
  TEACHER: "Учитель",
  STUDENT: "Ученик",
  DEVELOPER: "",
  CALLCENTER: "Тех поддержка",
};

export const roles = [
    { role_id: 1, label: "SUPER_BOSS" },
    { role_id: 2, label: "BOSS" },
    { role_id: 3, label: "FRANCH" },
    { role_id: 4, label: "TEACHER" },
    { role_id: 5, label: "STUDENT" },
    { role_id: 10, label: "SUPPORT" }
];

//
// export default {
//     SUPERADMIN: 'superadmin',
//     BOSS: "boss",
//     FRANCHISE: "",
//     TEACHER: "",
//     PUPIL: "",
//     DEVELOPER: "",
//     CALLCENTER: "",
// }
