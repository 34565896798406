import Types from "./../constants/hwReducer";

const defaultState = {
  topic: 0,
  day: 0,
  templatesName_select: null,
  calendar_tasks: [],
  load: false,
  students_list: [],
  student: "",
  templateType: null,
  templatesName: null,
  hideSubmitButton: true
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.CHANGE:
    case Types.INIT_TEMPLATES_NAME:
    case Types.INIT_STUDENTS_LIST:
    case Types.INIT_CALENDAR_TASKS:
      return { ...state, ...action.homework };
    case Types.CLEAR_HW_DATA:
      return { ...defaultState };
    
    case Types.SET_TEMPLATES_NAME:
      return {
        ...state,
        templatesName: action.payload
      };
    
    case Types.HIDE_SUBMIT_BUTTON:
      return {
        ...state,
        hideSubmitButton: action.payload
      };
    default:
      return state;
  }
};
